
const BASE = 'blister-pack-customer-frontend';

class Logger {
    generateMessage( message: any, source: any, identity="Unknown"){
        let timestamp = new Date().toUTCString();
        return `[${timestamp}] ${BASE}:${source} ${identity} ${message}`;
    }

    trace(message: any, source: any){
        console.trace(
            this.generateMessage(message, source)
        );
    }
    
    info(message: any, component: any, identity="Unknown"){
        console.info(
            this.generateMessage(message, component, identity)
        );
    }

    warn(message: any, source: any){
        console.warn(
            this.generateMessage(message, source)
        );
    }

    error(message: any, source: any){
        console.error(
            this.generateMessage(message, source)
        );
    }

    debug(message: any, source: any){
        console.debug(
            this.generateMessage(message, source)
        );
    }

}

export default new Logger();