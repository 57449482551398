// Wizard 
//Step States
export const STEP_INIT = "STEP_INIT"
export const STEP_PROCESSING = "STEP_PROCESSING"
export const STEP_SUCCESS = "STEP_SUCCESS"
export const STEP_FAILED = "STEP_FAILED"
export const STEP_ACTIVE = "STEP_ACTIVE"
export const STEP_INACTIVE = "STEP_INACTIVE"

//ERRORS
export const ERROR_NOT_ELIGIBLE = "ERROR_NOT_ELIGIBLE"
export const API_ERROR_INVALID_ROLLBACK = "invalid-rollback"
export const API_ERROR = "api-error"

export const HTTP_STATUS_400 = 400
export const HTTP_STATUS_404 = 404

// Checklist States
export const STATE_DONE = "STATE DONE"
export const STATE_DOING = "STATE DOING"
export const STATE_TODO = "STATE TODO"
export const STATE_FAILED = "STATE FAILED"

export const ERROR_FORM = "form-error"
export const ERROR_WIZARD = "wizard-error"


