import axios, { CancelToken, CancelTokenSource } from "axios";
import { useCallback, useEffect, useRef } from "react";
import { configurations, DocumentData, DropDownListResponse, routes, UserRegistration, VerifiedCustomerDetails } from "./configs";
import { IdTypes, SimQuotaData, SimQuotaResponse } from "./interfaces";


interface KYCProcessSettings {
    language: string,
    redirectUrl?: string,
    identificationType?: number,
    OmniChannelRequired?: Boolean
}
interface StartIdentificationRequest extends ApiDefaultRequest {
    emailAddress?: string,
    firstName?: string,
    middleName?: string,
    lastName?: string,
    personalIdentityCode?: string,
    phoneNumber?: string,
    dateOfBirth?: Date,
    flowData?: KYCProcessSettings
}
export interface Registration {
    email?: string,
    firstName?: string,
    middleName?: string,
    lastName?: string,
    personalCode?: string,
    phoneNumber?: number,
    dateOfBirth?: Date
}

interface StartIdentificationRequest_v2 extends ApiDefaultRequest {
    registration?: Registration,
    externalReferenceId?: string,
    setupId?: string
}


interface ApiDefaultRequest {
    token?: CancelToken;
}

export interface GetSimDetailRequest extends ApiDefaultRequest {
    activationCode: string
}

export interface SimPackage {
    iccid: string,
    msisdn: string
}

export interface SubmitRegistrationRequest extends ApiDefaultRequest {
    userRegistration: UserRegistration
}

export interface GetRegionRequest extends ApiDefaultRequest {
    regionNumber: number
}
export interface GetSecurityQuestionsRequest extends ApiDefaultRequest {
}
export interface GetCountriesRequest extends ApiDefaultRequest {
}

export interface CachedDTO<T> {
    isFromCache: boolean,
    expiresIn: Date,
    cachedResult: T
}

export const useCancelToken = () => {
    //TODO: put this in hooks
    const source = useRef<CancelTokenSource>();
    const isCancel = axios.isCancel
    const getCancelToken = useCallback(() => {
        source.current = axios.CancelToken.source();
        return source.current.token;
    }, []);

    useEffect(
        () => () => {
            if (source.current)
                source.current.cancel();
        },
        []
    );

    return { getCancelToken, isCancel };
};
const api = axios.create({
    // baseURL: configurations.BLISTER_PACK_API_ENDPOINT,
    headers: configurations.Headers,
    withCredentials: true,
});
let flow: KYCProcessSettings = { language: "en", redirectUrl: "", identificationType: 0, OmniChannelRequired: undefined };

const StartIdentificationRequest =
    async (
        { emailAddress = "", firstName = "", middleName = "", lastName = "", personalIdentityCode = "", phoneNumber = "", dateOfBirth = new Date(), flowData, token }: StartIdentificationRequest
    ) => {
        if (flowData == null) {
            flowData = flow;
        }
        let requestObject: StartIdentificationRequest = { flowData: flowData, emailAddress: emailAddress, firstName: firstName, middleName: middleName, lastName: lastName, personalIdentityCode: personalIdentityCode, phoneNumber: phoneNumber, dateOfBirth: dateOfBirth, token: token }
        console.log('request: ', requestObject);
        const { data: response, status } = await api.post(
            `${routes.StartIdentification}`, requestObject,
            { cancelToken: token, }
        );
        if (status == 200) {
            const { redirectURL, identificationID } = response;
            console.log("about to redirect to credit bureau portal");
            //notification component; notify customer that they are about to be redirected
            //wait 2 seconds
            console.log("redirectURL: ", redirectURL);
            //window.open(redirectURL);
        } else {
            console.log("unsuccessful call to the kyc endpoint, ", status);
        }
        return response;
    }


const StartIdentificationRequest_v2 =
    async (
        { registration, setupId, externalReferenceId, token }: StartIdentificationRequest_v2
    ) => {

        let requestObject: StartIdentificationRequest_v2 = { registration, setupId, externalReferenceId, token: token }
        console.log('request: ', requestObject);
        const { data: response, status } = await api.post(
            `${routes.StartIdentification}`, requestObject,
            { cancelToken: token, }
        );
        if (status == 200) {
            const { redirectURL, identificationID } = response;
            console.log("about to redirect to credit bureau portal");
            //notification component; notify customer that they are about to be redirected
            //wait 2 seconds
            console.log("redirectURL: ", redirectURL);
            //window.open(redirectURL);
        } else {
            console.log("unsuccessful call to the kyc endpoint, ", status);
        }
        return response;
    }



const getSimDetails =
    async ({ token, activationCode }: GetSimDetailRequest) => {

        const result = await api.get<CachedDTO<SimPackage[]>>(
            `${routes.getSimDetails}/?activationCode=${activationCode}`,
            { cancelToken: token }
        )

        const response = {
            data: result.data.cachedResult,
            status: result.status
        }

        return response;
    }

const submitRegistration =
    async ({ token, userRegistration }: SubmitRegistrationRequest) => {

        const { data, status } = await api.post<SimPackage[]>(
            `${routes.submitRegistration}`, userRegistration,
            { cancelToken: token }
        )
        return data;
    }

const getRegions =
    async ({ token, regionNumber }: GetRegionRequest) : Promise<DropDownListResponse>=> {
        const { data } = await api.get<CachedDTO<DropDownListResponse>>(
            `${routes.getRegions}/?request=${regionNumber}`,
            { cancelToken: token }
        )
        return data.cachedResult;
    }
const getSecurityQuestions =
async ({ token }: GetSecurityQuestionsRequest): Promise<DropDownListResponse> => {
    const { data } = await api.get<CachedDTO<DropDownListResponse>>(
        `${routes.getSecurityQuestions}`,
            { cancelToken: token }
        )
        return data.cachedResult;
    }

const fetchIdTypes = async (token: CancelToken): Promise<IdTypes[]> => {
    const results = await api.get<CachedDTO<{ data: IdTypes[] }>>(`${routes.IdTypes}`, { cancelToken: token });
    if (!results.data?.cachedResult) {
        return [];
    }

    const cachedResults = results.data;

    // Convert the CachedDTO<IdTypes[]> to IdTypes[]
    const cache = cachedResults.cachedResult.data;

    const idTypes = cache.map((item: IdTypes) => {
        return {
            id: item.id,
            name: item.name
        }
    });

    return idTypes;
}

const checkSimQuota = async (value: SimQuotaData): Promise<SimQuotaResponse> => {
    const request = value;
    const results = await api.post(`${routes.SimQuotaCheck}?idType=${request.idType}&idNumber=${request.idNumber}`);
    if (!results.data) {
        throw new Error("Unable to check the SIM quota.");
    }
    const data: SimQuotaResponse = results.data;
    return data;
}

const getCustomerDetails = async (value: string): Promise<any> => {
    const request = value;
    const result = await api.post<CachedDTO<VerifiedCustomerDetails>>(`${routes.getCustomerIdVerificationData}?request=${request}`); //https://localhost:7176/IdVerification/GetCustomerIdVerificationData?request=[setup_id]

    const data: VerifiedCustomerDetails = result.data.cachedResult;
    return data;
}

const getCountries =
    async ({ token }: GetCountriesRequest): Promise<DropDownListResponse> => {
        const { data } = await api.get<CachedDTO<DropDownListResponse>>(
            `${routes.getCountries}`,
            { cancelToken: token }
        )
        return data.cachedResult;
    }

const API = {
    getSimDetails: getSimDetails,
    submitRegistration: submitRegistration,
    getRegions: getRegions,
    StartIdentificationRequest: StartIdentificationRequest_v2,
    getSecurityQuestions: getSecurityQuestions,
    fetchIdTypes: fetchIdTypes,
    checkSimQuota: checkSimQuota,
    getCustomerDetails: getCustomerDetails,
    getCountries: getCountries
}
export default API;