import React from "react";

export const Button = ({
  children,
  disabled,
  action,
  className,
  onMouseEnter,
}: {
  children?: React.ReactNode | undefined;
  disabled?: boolean | undefined;
  className?: string | undefined;
  action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <button
      className={`shadow focus:shadow-outline focus:outline-none
            bg-gradient-to-r from-cyan to-fushia 
            rounded-full
            font-bold text-white
            w-56 text-lg py-2 md:py-1 ${className}`}
      disabled={disabled}
      onClick={action}
      onMouseOver={onMouseEnter}
    >
      {children}
    </button>
  );
};

export default Button;
