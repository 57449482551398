import React, { ReactNode, isValidElement, useEffect, useRef, useState } from "react";
import BaseCard from "./Card";
import Logger from "../../utils/Logger";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import AlertConfiguration from "../../config/AlertConfiguration.json"
import { el } from "date-fns/locale";

const CloseIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={className}
    //   title="Close Icon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};



const SOURCE = "Message Card";

export enum MessageCardType {
  Warning = "bg-amber-500",
  Default = "bg-gradient-to-b from-cyan to-fushia"
}

function MessageCard({
  children,
  title,
  close = false,
  onClose = () => { },
  autoClose = true,
  type,
  duration,
}: {
  children?: React.ReactNode;
  title?: string;
  close?: boolean;
  onClose?: (e: React.SyntheticEvent) => void;
  autoClose?: boolean;
  type: MessageCardType;
  duration?: number
}) {

  const timeoutIdRef = useRef<number>(0);

  useEffect(() => {
    if (autoClose === true && close !== true) {

      let timeout;
      console.log("duration form message card", duration)
      if (duration !== null && duration !== undefined && duration !== 0 && typeof duration === 'number') {
        timeout = setTimeout(onClose, duration);
      }
      else {
        timeout = setTimeout(onClose, AlertConfiguration.DEFAULT_ALERT_TIMEOUT); //Default 15 sec
      }
      //get timeout id
      timeoutIdRef.current = timeout;
    }

    return () => {
      if (timeoutIdRef.current) {
        //get rid of old timeout
        clearTimeout(timeoutIdRef.current);
      }
    }
  }, [close, autoClose, duration])


  const handleClose = (e: React.SyntheticEvent) => {
    Logger.info("Entered the handleClose function", SOURCE);
    e.preventDefault();
    onClose(e);
    Logger.info("Entered the handleClose function", SOURCE);
  };

  return (
    <BaseCard
      dataTestId="message-card"
      className={` ${close ? "hidden" : " animate-iconLoading"} `}
    >
      <div className={`${type} pl-2 w-full`}>
        {/* <div className="bg-gradient-to-b from-cyan to-fushia pl-2"> */}
        <div className=" bg-white p-4 flex flex-row items-center">
          <ExclamationCircleIcon className={`h-8 mr-2 ${type ? "text-amber-500" : ""}`} />
          <div>
            <h1 className="flex justify-between mb-1">
              <span className="text-navy text-lg md:text-xl mr-2 md:mr-0">
                {title}
              </span>
              <span onClick={handleClose} className="cursor-pointer" data-testid="close-button">
                <CloseIcon className="w-8 text-gray-500" />
              </span>
            </h1>
            <div className="mr-4 text-base text-gray-600 flex flex-col flex-grow">
              {
                children?.toString().includes("<b>") ?
                  <span dangerouslySetInnerHTML={{ __html: children?.toString() ?? "<b>No string</b>" }}></span>
                  : children
              }
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  );
}

export default MessageCard;
