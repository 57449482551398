import axios, { CancelTokenSource } from "axios";
import { useCallback, useEffect, useRef } from "react";

export const useCancelToken = () => {
    const source = useRef<CancelTokenSource>();
    const isCancel = axios.isCancel
    const getCancelToken = useCallback(() => {
        source.current = axios.CancelToken.source();
        return source.current.token;
    }, []);

    useEffect(
        () => () => {
            if (source.current) 
                source.current.cancel();
            },
        []
    );

    return { getCancelToken, isCancel };
};