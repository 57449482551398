import React, { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate, Route, Routes } from "react-router-dom";

import logger from "../../utils/Logger";

import ProgressBar from "../ProgressBars";
import ProgressButton, { InlineButton, Button } from "../Buttons";
import { ApplicationContext } from "../../context/AppContext";

import {
  STEP_INIT,
  STEP_PROCESSING,
  STEP_SUCCESS,
  STEP_FAILED,
  STEP_ACTIVE,
  STEP_INACTIVE,
  ERROR_FORM,
} from "../../config/constants";

const COMPONENT_NAME = "Wizard";

const ANIMATIONS = {
  next: {
    enter: "animate-nextEnter",
    exit: "animate-nextExit",
  },
  previous: {
    enter: "animate-prevEnter",
    exit: "animate-prevExit",
  },
};

let WIZARD_ERROR = { valid: false, message: "" };
export const WIZARD_STEP = {
  checkErrors: (show = true) => {
    return true;
  },
};
export type WizardStep = {
  name: string;
  animation?: string;
  percent: string;
  url: string;
  label?: string;
  component?: JSX.Element | undefined;
  action?: () => Promise<{ redirect: string } | undefined>;
  isValid?: boolean;
  checkErrors?: (show: boolean) => boolean;
  isStageOptionClickable?: boolean
};

function Wizard({
  steps,
  finish = "",
  className = "",
  error = WIZARD_ERROR,
  testId = "",
  hasError = false,
  fields = {},
  hasData = () => true,
}: {
  steps: WizardStep[];
  finish?: string;
  className?: string;
  error?: any;
  testId?: string;
  hasError?: boolean;
  fields?: Record<any, any>;
  hasData?: any;
}) {
  const location = useLocation();
  let history = useNavigate();
  const [animation, setAnimation] = useState(ANIMATIONS.next.enter);
  const { showAppError } = useContext(ApplicationContext);

  const findStep = useCallback(() => {
    logger.info(`Enter findStep function`, COMPONENT_NAME);
    let stepIndex = 0;
    steps.forEach((item, index) => {
      if (item.url === location.pathname && location.search.length >= 0) {
        stepIndex = index;
      }
    });
    logger.info(
      `Exit findStep function with stepIndex: ${stepIndex}`,
      COMPONENT_NAME
    );
    return stepIndex;
  }, [location.pathname, steps]);

  const [index, setIndex] = useState(findStep());
  const [state, setState] = useState(STEP_INACTIVE);

  const checkErrors = useCallback(
    (show: boolean) => {
      logger.info("Enter checkErrors: " + index, COMPONENT_NAME);
      let step = steps[index];
      if (step && step.checkErrors) {
        step.checkErrors(show);
      }
      logger.info("Exit checkErrors", COMPONENT_NAME);
    },
    [index, steps]
  );

  const checkState = () => {
    if (hasError) {
      setState(STEP_INACTIVE);
    } else {
      setState(STEP_ACTIVE);
    }
  };

  useEffect(() => {
    const resetState = () => {
      logger.info("Enter resetState", COMPONENT_NAME);
      if (steps[index].isValid) {
        setState(STEP_ACTIVE);
      } else if (state != STEP_FAILED) {
        setState(STEP_INACTIVE);
      }
      logger.info("Exit resetState", COMPONENT_NAME);
    };
    resetState();
    return () => { };
  }, [steps[index].isValid]);

  useEffect(() => {
    const refreshErrors = () => {
      logger.info("Enter refreshErrors", COMPONENT_NAME);
      checkErrors(false);
      logger.info("Exit refreshErrors", COMPONENT_NAME);
    };
    refreshErrors();
    return () => { };
  }, [checkErrors, fields, index]);

  // useEffect(() => {
  //   const resetState = () => {
  //     logger.info("Enter resetState", COMPONENT_NAME);
  //     if (hasError) {
  //       setState(STEP_INACTIVE);
  //     } else {
  //       setState(STEP_ACTIVE);
  //     }
  //     logger.info("Exit resetState", COMPONENT_NAME);
  //   };
  //   resetState();
  //   return () => {};
  // }, [hasError]);

  useEffect(() => {
    const updateStep = () => {
      logger.info("Enter the updateStep function", "Wizard");
      let index = findStep();
      setIndex(index);
      logger.info(`Exit the updateStep function with step ${index}`, "Wizard");
    };
    updateStep();
    return () => {
      logger.info("Clean up the updateStep function", "Wizard");
    };
  }, [findStep, location]);

  const performAction = async () => {
    logger.info("Entered the handleAction method.", COMPONENT_NAME);

    let step = steps[index];
    if (step.action) {
      logger.info("Changed the Page state to Processing.", COMPONENT_NAME);
      setState(STEP_PROCESSING);
      return await step.action().then((data) => {
        logger.info("Changed the Page state to Success.", COMPONENT_NAME);
        setState(STEP_SUCCESS);
        return data;
      });
    }
  };

  const navigate = (url: string, animation = ANIMATIONS.next) => {
    logger.info("Enter navigate", COMPONENT_NAME);
    setAnimation(animation.enter);

    history(url);
    // setState(STEP_INACTIVE);

    logger.info("Exit navigate", COMPONENT_NAME);
  };

  const goToStep = (stepIndex: number, animations = ANIMATIONS.next) => {
    logger.info("Enter goTo function with index:" + index, COMPONENT_NAME);
    let url = steps[stepIndex].url;
    setAnimation(animations.exit);
    setTimeout(() => {
      setIndex(stepIndex);
      navigate(url, animations);
    }, 1000);

    if (!hasData(index)) {
      setIndex(0);
      navigate(steps[0].url, animations);

      setTimeout(() => {
        showAppError(
          "Navigation Error",
          "There was an error retrieving your progress. This error occurs when the browser navigates away from the wizard before completing the process."
        );
      }, 1000);
    }
  };
  const goToNext = async () => {
    logger.info("Enter goToNext function with index:" + index, COMPONENT_NAME);
    let nextIndex = index + 1;
    if (nextIndex < steps.length) {
      await performAction()
        .then((data) => {
          logger.info(
            "Performed action and got back redirect: " + JSON.stringify(data),
            COMPONENT_NAME
          );
          if (data && data.redirect) {
            let url = data.redirect;
            setTimeout(() => {
              navigate(url);
              setState(STEP_ACTIVE);
            }, 1000);
          }
        })
        .catch((error: string) => {
          logger.info(
            "An error was thrown in the flow: " + JSON.stringify(error),
            COMPONENT_NAME
          );
          setState(STEP_FAILED);
          if (error !== ERROR_FORM) {
            showAppError();
          }
        });
    }
  };

  const goToPrevious = () => {
    logger.info(
      "Enter goToPrevious function with index:" + index,
      COMPONENT_NAME
    );
    let prevIndex = index - 1;
    if (prevIndex > -1) {
      goToStep(prevIndex, ANIMATIONS.previous);
    }
  };

  const goToFinish = () => {
    logger.info("Enter goToFinish", COMPONENT_NAME);
    setTimeout(() => {
      navigate(finish);
    }, 1000);
  };

  let stepList = steps.map(function (step, index) {
    return (
      <Route path={step.url} key={index} element={step.component} />
    );
  });

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (state === STEP_ACTIVE && steps[index].isValid) {
      goToNext();
    }
  };

  const openUserManual = () => {
    //get pdf url from configuration
    let pdfUrl = process.env.REACT_APP_CUSTOMER_PORTAL_USER_MANUAL_URL;

    //check if the pdf url from configurtion is null, undefined or is an empty string
    if (pdfUrl === undefined || pdfUrl === null || pdfUrl !== undefined && pdfUrl !== null && pdfUrl.trim() === "") {
      //use default manual from public if url not found in configuration
      const pdfName = "SIMToGo-UserManual.pdf";
      pdfUrl = process.env.PUBLIC_URL + '/pdfs/' + pdfName;
    }
    window.open(pdfUrl); 
  };

  return (
    <div className="flex flex-col flex-grow w-full" data-testid={testId}>
      <ProgressBar stage={steps[index]} stages={steps} />
      <div
        data-testid="page-animation"
        className={`flex flex-col ${className} ${animation} w-full max-w-full`}
      >
        <form onSubmit={handleSubmit}>
          <Routes>{stepList}</Routes>
        </form>
      </div>
      <div
        className={`
                flex flex-col-reverse w-full pr-4
                items-center mt-4
                md:flex-row md:justify-between md:mt-0 `}
      >
        {index > 0 && index !== steps.length - 1 ? (
          <InlineButton
            label="Previous"
            action={(e) => {
              goToPrevious();
            }}
          />
        ) : (
          <div>
            <a href="/"
              className=" text-cyan pl-3 text-sm mt-2"
              onClick={() => openUserManual()}>
              Need Help?</a>
            <a
              href={process.env.REACT_APP_ADD_CUSTOMER_CARE_LINK !== undefined ? process.env.REACT_APP_ADD_CUSTOMER_CARE_LINK : "/"}
              className=" text-cyan pl-3 text-sm mt-2">
              {process.env.REACT_APP_ADD_CUSTOMER_CARE_TEXT !== undefined && process.env.REACT_APP_ADD_CUSTOMER_CARE_TEXT?.toString().toLowerCase() === "true" ? "Contact Customer Care" : null}
            </a>
          </div>
        )}


        {index < steps.length - 1 ? (
          <ProgressButton
            label={steps[index].label || "Next"}
            action={handleSubmit}
            state={state}
          // onMouseEnter={checkErrors}
          />
        ) : null}
        {finish && index === steps.length - 1 ? (
          <Button action={goToFinish}>Finish</Button>
        ) : null}
      </div>
    </div>
  );
}

export default Wizard;
