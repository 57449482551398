import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import Avatar from "../../components/Avatar/Avatar";
import Card from "../../components/Cards";
import { DropDownList, Input, InputGroup } from "../../components/Inputs";
import { IOption } from "../../components/Inputs/DropDownList";
import { Loader } from "../../components/Loaders";
import { ERROR_FORM } from "../../config/constants";
import { ApplicationContext } from "../../context/AppContext";
import { WizardContext } from "../../context/WizardContext";
import API, { Registration } from "../../services/ApiService";
import ErrorMessages from "../../config/ErrorMessageConfiguration.json";
import IdTypes from "../../config/IdTypeConfiguration.json";
import RequiredFieldLength from "../../config/RequiredFieldLengthConfiguration.json";
import WarningMessages from "../../config/WarningMessageConfiguration.json";
import RegistrationActivationMessages from "../../config/RegistrationActivationConfiguration.json";
import { useCancelToken } from "../../components/hooks/useCancelToken";
import AdditionalFormField from "../../components/Form/AdditionalFormField";
import { DocumentData, IdTypeMapping, IdVerificationRegistrationStatus } from "../../services/configs";
import { SignalRContext } from "../../context/SignalRContext";
import WizardMessages from '../../config/WizardConfiguration.json'
import { LargeSpinner } from "../../components/Spinners/LargeSpinner";
import { usePersistentStorageValue } from "../../components/hooks/usePersistentStorageValue";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../components/hooks/useQuery";
import { ErrorMessageCongiguraton } from "../../config/ErrorMessagesConfiguration";
import { MessageCardType } from "../../components/Cards/MessageCard";
import { getSelectedIdType, isIdTypeMismatched } from "../../helpers/SimRegistrationActivationHandler";

enum SimRegistrationWizardAction {
    none,
    checkSimQuota,
    additionalFormFields,
    idVerification
}

function SameTabSimRegistrationActivation({ isTermsAndConditionAccepted }: { isTermsAndConditionAccepted: boolean }) {
    const { setAction, setIsValid } = useContext(WizardContext);
    const { showAppError } = useContext(ApplicationContext);

    const [showIdDetailsSection, _setShowIdDetailsSection] = useState(true);
    const [showWarningSection, setShowWarningSection] = useState(false);
    const [showAdditionalFormSection, _setShowAdditionalFormSection] = useState(false);
    const [showActivationSection, _setShowActivationSection] = useState(false);
    const [idType, setIdType] = useState<number | undefined>(undefined);
    const [idNumberErrorMessage, setIdNumberErrorMessage] = useState<string[]>([]);
    const [idTypeErrorMessage, setIdTypeErrorMessage] = useState<string | undefined>(undefined);

    const [idTypeOptions, setIdTypeOptions] = useState([{ name: '' } as IOption])
    const [isIdDetailsLoading, setIsIdDetailsLoading] = useState(true)
    const [isIdentificationDetailsDisabled, setIsIdentificationDetailsDisabled] = useState(false)
    // const [idVericationLoading, setIdVericationLoading] = useState(false);
    const [idVericationLoading, setIdVericationLoading] = useState(() => {

        const found = localStorage.getItem('idVerificationStarted');
        const isLoading = found ? JSON.parse(found) : false;

        return isLoading;
    });
    const shouldCheckFormIdDetailsValidation = useRef(false);
    const { getCancelToken } = useCancelToken();

    const [documentData, setdocumentData] = useState<DocumentData | undefined>(undefined);
    const [timeOutValue, setTimeOutValue] = useState<NodeJS.Timeout>();
    const [registrationStatus, setRegistrationStatus] = useState("")

    //#region usePersistentStorageValue
    const [idVerificationId, setIdVerificationId] = usePersistentStorageValue<string>("idVerificationId", "");
    const [wizardAction, _setWizardAction] = usePersistentStorageValue<SimRegistrationWizardAction>("wizardAction", SimRegistrationWizardAction.checkSimQuota);
    const [idNumber, setIdNumber] = usePersistentStorageValue<string>("idNumber", "");
    const [idVerificationStarted, setIdVerificationStarted] = usePersistentStorageValue("idVerificationStarted", false);
    const [selectedIdType, setSelectedIdType] = usePersistentStorageValue<number>("idType", IdTypeMapping.NO_MATCH);
    const [userData, setUserData] = usePersistentStorageValue<DocumentData | undefined>("userData", undefined);
    const [termsAndConditionAccepted, setTermsAndConditionAccepted] = usePersistentStorageValue<boolean>("termsAndCondition", isTermsAndConditionAccepted);
    const [preventNextStep, setPreventNextStep] = usePersistentStorageValue<boolean>("preventNextStep", !isTermsAndConditionAccepted);
    //#endregion


    const query = useQuery();

    const navigate = useNavigate();

    // Identification Details Form Validation
    const identificationDetailsFormValidation = () => {
        if (!idType) {
            return false;
        }
        if (!shouldCheckFormIdDetailsValidation.current) {
            shouldCheckFormIdDetailsValidation.current = true;
            return false;
        }
        let isFormValid = true;

        let idNumberErrorMessages: string[] = []
        //Validation on Id Number
        if (!idNumber) {
            isFormValid = false
            idNumberErrorMessages.push(ErrorMessages.REQUIRED_FIELD_ID_NUMBER);
        } else if (idType && idType === IdTypes.DRIVERS_LICENSE) {
            if (!(/^\+?(0|[1-9]\d*)$/.test(idNumber))) {
                isFormValid = false
                idNumberErrorMessages.push(ErrorMessages.ONLY_DIGITS_DRIVERS_LICENSE);
            } else if (idNumber.length !== RequiredFieldLength.DRIVERS_LICENSE_FIELD_LENGTH) {
                isFormValid = false
                idNumberErrorMessages.push(ErrorMessages.INVALID_DRIVERS_LICENSE_LENGTH);
            }
        } else if (idType && idType === IdTypes.PASSPORT) {
            if ((idNumber.match(/[0-9]/g) || []).length !== RequiredFieldLength.PASSPORT_NUMBER_LENGTH) {
                isFormValid = false
                idNumberErrorMessages.push(ErrorMessages.INVALID_PASSPORT_NUMBER_LENGTH)
            }
            if ((idNumber.match(/[a-zA-z]/g) || []).length !== RequiredFieldLength.PASSPORT_CHARACTER_LENGTH) {
                isFormValid = false
                idNumberErrorMessages.push(ErrorMessages.INVALID_PASSPORT_CHARACTER_LENGTH)
            }
        }
        setIdNumberErrorMessage(idNumberErrorMessages)

        // Validation on Id Type
        if (idType === Number.NEGATIVE_INFINITY) {
            setIdTypeErrorMessage(ErrorMessages.FETCH_ID_TYPES_FAILURE)
            isFormValid = false
        } else {
            setIdTypeErrorMessage(undefined)
        }

        setIsValid(isFormValid)
        return isFormValid;
    }



    //#region  Credit Bureau 

    //start id verification 
    const startIdProcess = async () => {
        let registration: Registration = {
            personalCode: idNumber,
        };
        const data = await API.StartIdentificationRequest({ registration, token: getCancelToken() })
        if (!!data && data.redirectURL) {
            console.log("redirect coming from api: ", data.redirectURL);
            // setShow(true);
            setIdNumber(idNumber);
            let url;
            try {
                url = new URL(data.redirectURL);
                console.log("formed url: ", url);

                //open in ssame tab
                window.location.href = data.redirectURL;

                //store id verification id
                setIdVerificationId(data.id)

            } catch (err) {
                console.log("error when redirecting");
            }
        }
    }

    //set registration status
    const setRequestStatusFromData = (status: number) => {
        switch (status) {
            case 0:
                setRegistrationStatus(IdVerificationRegistrationStatus.APPROVED);
                break;
            case 1:
                setRegistrationStatus(IdVerificationRegistrationStatus.FAILED);
                break;
            default:
                break;
        }
    }


    const resetIdVerificationFlowVariables = () => {
        localStorage.removeItem("idNumber");
        localStorage.removeItem("userData");
        localStorage.removeItem("idVerificationId");

        //set loading state to for to false id verification loading process after id check
        setIdVericationLoading(false);
        setIdVerificationStarted(false);

        _setWizardAction(SimRegistrationWizardAction.checkSimQuota)

        //clear timeout to reload screen
        clearTimeout(timeOutValue);
    }


    const setIdVerificationTimeout = (timeoutValue: number) => {
        const timeout = setTimeout(() => {
            localStorage.removeItem('idVerificationStarted');

            //reset local storage variables on time out 
            resetIdVerificationFlowVariables();

            if (query.get("success") === "true") {
                navigate(`/registration`);
            }
            else {

                window.location.reload();
            }
            showAppError(ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationTimeout.Title, ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationTimeout.Content);
        },
            timeoutValue ?? 300000
            // parseInt(process.env.REACT_APP_ID_VERIFICATION_TIMEOUT_VALUE ?? "300000")
        ); //Default 5 minutes

        setTimeOutValue(timeout);
    }


    const onRegistrationApproved = () => {
        //update wizard action
        _setWizardAction(SimRegistrationWizardAction.additionalFormFields);

        //clear all localStarage values that indicates that id verification has started
        resetIdVerificationFlowVariables();

        //update states that tell what view to render
        _setShowIdDetailsSection(false);
        _setShowAdditionalFormSection(true);

    }

    const onSimQuotaNotExceeded = () => {
        setShowWarningSection(false);
        setIsIdentificationDetailsDisabled(true);

        _setWizardAction(SimRegistrationWizardAction.idVerification);
        _setShowIdDetailsSection(false);

        //set idNumber to localStorage
        localStorage.setItem('idNumber', JSON.stringify(idNumber));

        //set selected id type
        setSelectedIdType(idType ?? IdTypeMapping.NO_MATCH);

        //start id verification process
        startIdProcess();

        //set loading state to true for id verification loading process after id check
        setIdVericationLoading(true);
        setIdVerificationStarted(true);

        //Set timeout for screen after x amount of minutes/seconds
        const timeoutValue = parseInt(process.env.REACT_APP_ID_VERIFICATION_TIMEOUT_VALUE ?? "300000")
        setIdVerificationTimeout(timeoutValue);
    }

    //#region Flow for when Registration was APPROVED/FAILED

    const onRegistrationFailure = () => {

        //set show idDetail to true to show section to select id type and enter id number
        _setShowIdDetailsSection(true);

        //re-enable the idDetail form
        setIsIdentificationDetailsDisabled(false);

        //set show state to show registration form to false
        _setShowAdditionalFormSection(false);

        //clear all localStarage values that indicates that id verification has started
        resetIdVerificationFlowVariables()

        //reset wizard action
        _setWizardAction(SimRegistrationWizardAction.checkSimQuota);

        //redirect to registration to restart id verification process
        //   navigate(`/`);
        navigate(`/registration`);

        //show notification that states that the registraion process failed
        showAppError(ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationFailed.Title, ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationFailed.Content);
    }
    //#endregion


    const onIdMismatched = () => {
        //reset local storage
        resetIdVerificationFlowVariables();

        //RESET WIZARD ACTION & ID TYPE
        _setWizardAction(SimRegistrationWizardAction.checkSimQuota);
        setSelectedIdType(IdTypeMapping.NO_MATCH);

        //re-enable the idDetail form
        setIsIdentificationDetailsDisabled(false);

        //redirect to registration 
        navigate(`/registration`);

        //show id mismatch notification
        showAppError(ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationIdMismatched.Title,
            ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationIdMismatched.Content,
            MessageCardType.Default,
            ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationIdMismatched.Duration
        );
    }

    //get customer details
    const getCustomerDetails = async (setupId: string) => {
        console.log("entered getCustomerDetails");
        //Set timeout for screen after x amount of minutes/seconds
        //check if a timeout value has been added in config for approval before setting and starting timeout
        const timeoutValue = process.env.REACT_APP_ID_VERIFICATION_APPROVAL_TIMEOUT_VALUE ? parseInt(process.env.REACT_APP_ID_VERIFICATION_APPROVAL_TIMEOUT_VALUE) : 0;
        if (timeoutValue !== null && timeoutValue !== undefined && timeoutValue !== 0) {
            setIdVerificationTimeout(timeoutValue);
        }
        await API.getCustomerDetails(setupId).then(customerDetails => {

            let documentData = customerDetails.document;

            //CHECK IF ID IS MATCHED
            const isIdMismatched = isIdTypeMismatched(idNumber, selectedIdType, documentData.documentNumber, documentData.type);
            if (isIdMismatched) {
                onIdMismatched();
            }
            else {
                //set request status
                setRequestStatusFromData(customerDetails.status)
                setdocumentData({
                    FirstName: documentData.firstName ?? "",
                    MiddleName: documentData.middleName ?? "",
                    LastName: documentData.lastName ?? "",
                    DateOfBirth: documentData.dateOfBirth ?? new Date().toString(),
                    Nationality: "",
                    Gender: documentData.gender ?? "",
                    Address: documentData.address ?? "",
                    Country: "",
                    DocumentNumber: documentData.documentNumber ?? "",
                    DocumentType: documentData.type ?? "",
                    IdExpirartionDate: documentData.dateOfExpiration ?? new Date().toString(),
                    PersonalIdentityCode: 0,
                    DateOfIssue: new Date().toString()
                });

                console.log("registration state was updated with", customerDetails);
            }
        });
        console.log("existing getCustomerDetails");
    }
    //#endregion

    useEffect(() => {
        identificationDetailsFormValidation();
    }, [idNumber, idType]);

    useEffect(() => {

        console.log("idVerificationStarted", idVericationLoading)
        // setIdVericationLoading(idVerificationStarted ? idVerificationStarted : false);
        setIdVericationLoading(idVerificationStarted);

        //check url for success
        if (query.get("success") === "true") {
            setIdVerificationStarted(true);

            console.log("success is true")

            const id = localStorage.getItem("idVerificationId")?.toString();

            if (id && id !== "" && id?.length !== 0) {
                getCustomerDetails(idVerificationId);
            }
        }
        else if (query.get("success") === "false") {

            console.log("success was not true")

            //set the registration status as failed
            setRegistrationStatus(IdVerificationRegistrationStatus.FAILED)
        }


    }, [])

    // Set the Action of the Submit button
    useEffect(() => {
        if (wizardAction === SimRegistrationWizardAction.checkSimQuota) {
            setAction(() => () => new Promise<{ redirect: string } | undefined>(async (resolve, reject) => {
                const isIdDetailsFormValid = identificationDetailsFormValidation()
                setIsIdDetailsLoading(true)
                if (isIdDetailsFormValid) {
                    await API.checkSimQuota({ idNumber: idNumber, idType: idType! }).then(data => {
                        if (data.isQuotaExceeded) {
                            setIsValid(false);
                            setShowWarningSection(true)
                            reject(ERROR_FORM);
                        } else {
                            onSimQuotaNotExceeded();

                            //resolve back to second step - to additional form field
                            resolve({ redirect: WizardMessages.SECOND_STEP_LINK });

                        }

                    }).catch(_ => {
                        showAppError(ErrorMessages.ERROR_MESSAGE_TITLE, ErrorMessages.FETCH_SIM_QUOTA_CHECK_FAILURE)
                        reject(ERROR_FORM);
                    })
                }
                setIsIdDetailsLoading(false)
            }));
        }
        else if (registrationStatus === IdVerificationRegistrationStatus.APPROVED) {

            onRegistrationApproved();
        }
        else if (registrationStatus === IdVerificationRegistrationStatus.FAILED) {

            onRegistrationFailure();
        }
    }, [wizardAction, idNumber, idType, idVerificationStarted, registrationStatus]);


    // On Page Load
    useEffect(() => {
        setIsValid(true);
        API.fetchIdTypes(getCancelToken()).then(value => {
            if (!value || value.length === 0) {
                showAppError(ErrorMessages.ERROR_MESSAGE_TITLE, ErrorMessages.FETCH_ID_TYPES_FAILURE)
            }
            else {
                setIdTypeOptions(value.map(v => ({ name: v.name, id: v.id })))
                setIdType(value[0].id)
            }
        }).catch(_ => {
            setIdType(Number.NEGATIVE_INFINITY)
            setSelectedIdType(Number.NEGATIVE_INFINITY)
            showAppError(ErrorMessages.ERROR_MESSAGE_TITLE, ErrorMessages.FETCH_ID_TYPES_FAILURE)
        }).finally(() => {
            setIsIdDetailsLoading(false)
        });
    }, []);

    return (
        <>
            {(idVerificationStarted && documentData === undefined) ? (<div className=" transition-all ease-in-out duration-700 h-16 m-auto p-12 relative w-16"><div className="absolute h-full w-full"><LargeSpinner className="h-16 w-16" /></div></div>) : null}

            {/* Identification Details */}
            {showIdDetailsSection && !idVerificationStarted &&
                <Loader isLoading={isIdDetailsLoading} className="rounded-md">
                    <Card className="p-6 flex-col ">
                        <h1 className="text-navy text-2xl text-left mb-4 border-b border-solid border-gray-200 pb-1 font-medium">
                            {RegistrationActivationMessages.IDENTIFICATION_DETAILS_TITLE}
                        </h1>

                        <div className="p-10 flex flex-wrap justify-center">
                            <Avatar className="pr-5" />
                            <InputGroup className="flex flex-wrap justify-between grow">
                                <DropDownList disabled={isIdentificationDetailsDisabled} id="id-type" label={RegistrationActivationMessages.ID_TYPE_LABEL} errorMessage={idTypeErrorMessage} options={idTypeOptions} value={idType} onChange={(event: SyntheticEvent<HTMLSelectElement, Event>) => { setIdType(parseInt(event.currentTarget.value)); setSelectedIdType(parseInt(event.currentTarget.value)) }} />
                                <Input disabled={isIdentificationDetailsDisabled} id="id-number" label={RegistrationActivationMessages.ID_NUMBER_LABEL} px="px-3" errorMessage={idNumberErrorMessage} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdNumber(event.currentTarget.value)} type="text" />
                            </InputGroup>
                        </div>
                    </Card>
                </Loader>
            }

            {/* Warning Section */}
            <Card className={`flex-col relative transition-all ease-in-out duration-700 ${showWarningSection ? "opacity-100 scale-y-100 origin-top max-h-96" : "opacity-0 scale-y-0 origin-top max-h-0"}`}>
                <div className="absolute left-0 bg-red-500 h-full w-3"></div>
                <div className="px-20 pt-12 pb-7 ">
                    <h1 className="text-red-500 pb-5 text-xl font-medium">{WarningMessages.WARNING_TITLE}</h1>
                    <p className="h-96 overflow-y-auto">
                        {WarningMessages.SIM_QUOTA_EXCEED}
                        <img className="px-10 py-5" src="/sim_limit.png" alt="" />
                    </p>
                </div>

            </Card>

            {/* Additional Form Section */}
            {showAdditionalFormSection && !idVerificationStarted && documentData &&
                <div className=" transition-all ease-in-out duration-700">
                    <AdditionalFormField isTermsAndConditionAccepted={isTermsAndConditionAccepted} idTypeNumber={idType ? idType : 0} documentData={documentData ?? {}} />
                </div>
            }
        </>
    )
}

export default SameTabSimRegistrationActivation;