import React from "react";
import { DisabledButton } from "./DisabledButton";

export const SuccessButton = ({
  children,
  action,
}: {
  children?: React.ReactNode | undefined;
  action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="h-5 w-5 mr-2 m-auto fill-current animate-successIcon"
    >
      <title>Success Icon</title>
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
  );

  return <DisabledButton icon={icon} action={action}>{children}</DisabledButton>;
};
