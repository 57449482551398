import { FunctionComponent, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import logo from '../logo.svg';
const Layout: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}: PropsWithChildren<{}>) => {
  return (
    <>
      <header>
        <div className="justify-center max-w-3xl mx-auto px-2 md:px-12 lg:w-3/4 my-4 md:mb-6 mt-6 ">
          <Link to={"/"}>
            <h1 className=" flex justify-between text-Dred font-bold text-2xl md:text-3xl  md:text-center my-6 md:my-12">
              <img
                src={logo}
                className="w-24 md:w-32 inline-block"
                data-testid="digicel-logo"
                alt="digicel_logo"
              />
              <span
                className="text-navy font-semibold pt-2 text-base sm:text-lg md:text-xl "
                data-testid="site-title"
              >
                {process.env.REACT_APP_APPLICATION_NAME}
              </span>
            </h1>
          </Link>
        </div>
      </header>
      <main id="main-content" className="flex flex-grow justify-center  mx-auto lg:w-3/4 max-w-3xl w-full">{children}</main>
      <footer className=" flex  p-4 text-gray-500 justify-center items-center leading-3 my-5">
        {process.env.REACT_APP_APPLICATION_NAME_COPYRIGHT ?? "SIM-TO-GO"} © {process.env.REACT_APP_APPLICATION_COPYRIGHT_YEAR ?? new Date().getFullYear().toString()} - All Rights Reserved
        <span className="mx-1">Powered by</span> Digicel Group
      </footer>
    </>
  );
};

export default Layout;
