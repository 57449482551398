// import signalR, { HubConnectionBuilder } from "@microsoft/signalr";
import * as SignalR from "@microsoft/signalr";
import React, { FunctionComponent, PropsWithChildren, useState, useEffect, useRef, useContext } from "react";
import { RegistrationDetails } from "../components/SignalR";
import API, { Registration, useCancelToken } from "../services/ApiService";
import { configurations, IdVerificationRegistrationStatus } from "../services/configs";
import { useNavigate } from "react-router-dom";
import Logger from "../utils/Logger";
import NotificationLayer, { Errors } from "../components/Notification/NotificationLayer";
import { MessageCardType } from "../components/Cards/MessageCard";
import { useQuery } from "../components/hooks/useQuery";
import { usePersistentStorageValue } from "../components/hooks/usePersistentStorageValue";
import { IdentificationData } from "../services/interfaces";
import { ApplicationContext } from "./AppContext";
import { ErrorMessageCongiguraton } from "../config/ErrorMessagesConfiguration";

interface SignalRCtx {
  startConnection: (idNumber: string) => void;
  subscribe: (idNumber: string) => void;
  getRegistrationData: () => RegistrationDetails | undefined;
  getRequestStatus: () => string | undefined;
  connectionRef?: React.MutableRefObject<SignalR.HubConnection | undefined> | null;
  sendFailureMessage: (idNumber: string, message: string) => void;
  clearRequestStatus: () => void;
  invokeIdMismatch: () => void;
}

export const SignalRContext = React.createContext<SignalRCtx>({
  startConnection: (idNumber) => { },
  subscribe: (idNumber) => { },
  sendFailureMessage: (idNumber, message) => { },
  getRegistrationData: () => {
    return {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      DateOfBirth: "",
      IdType: "",
      IdNumber: "",
      IdExpiryDate: new Date(),
      TRN: "",
      EmailAddress: "",
      StreetAddress: "",
      City: "",
      Parish: "",
      SecurityQuestion: "",
      SecurityAnswer: ""
    }
  },
  getRequestStatus: () => {
    return "";
  },
  connectionRef: null,
  clearRequestStatus: () => { },
  invokeIdMismatch: () => { },
});

export const SignalRContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [idNumber, setIdNumber] = useState(() => {

    const found = localStorage.getItem('idNumber');
    const id = found ? JSON.parse(found) : '';

    return id;
  });
  const [retryCount, setRetryCount] = useState(0);
  const [requestStatus, setRequestStatus] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [showRetryNotification, setShowRetryNotification] = useState(false);
  const [showIdMismatchNotification, setShowIdMismatchNotification] = useState(false);
  const { getCancelToken, isCancel } = useCancelToken();
  const [registration, setRegistration] = useState<RegistrationDetails>({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    IdType: "",
    IdNumber: "",
    IdExpiryDate: new Date(),
    TRN: "",
    EmailAddress: "",
    StreetAddress: "",
    City: "",
    Parish: "",
    SecurityQuestion: "",
    SecurityAnswer: ""
  });
  const [idVerificationId, setIdVerificationId] = usePersistentStorageValue<string>("idVerificationId", "");
  const { showAppError } = useContext(ApplicationContext);


  const query = useQuery();

  const startConnection = (personalCode: string) => {
    setShowIdMismatchNotification(false);
    setShowNotification(false);

    const response = async () => {
      let registration: Registration = {
        personalCode: personalCode,
      };
      const data = await API.StartIdentificationRequest({ registration, token: getCancelToken() })
      if (!!data && data.redirectURL) {
        console.log("redirect coming from api: ", data.redirectURL);
        // setShow(true);
        setIdNumber(personalCode);
        let url;
        try {
          url = new URL(data.redirectURL);
          console.log("formed url: ", url);
          setShow(true);
          //subscribe to group
          // await subscribe(personalCode);
          setIdVerificationId(data.id)
          await subscribe(data.id);
          //open in new
          window.open(data.redirectURL)

        } catch (err) {
          console.log("error when redirecting");
        }
      }
    };
    response().catch(console.error);
  };

  const getRegistrationData = () => {
    return registration;
  };
  const getRequestStatus = () => {
    return requestStatus;
  };

  function incrementRetryCount() {
    setRetryCount((prevValue) => {
      console.log("inside the setter, prev: ", prevValue);
      let incrementVal = prevValue + 1;
      console.log("inside the setter, incremement: ", incrementVal);

      return incrementVal;
    });
  }


  let connectionRef = useRef<SignalR.HubConnection>();
  useEffect(() => {
    // if (show) {
    setUpConnection()
      .then((connection) => {
        //set connection ref
        connectionRef.current = connection;

        //TODO: IF THE THEN IS LOGGED BUT THE INVOKE MESSAGE IS NOT, THEN THEIR WAS A CONNECTION FAILURE, FOLLOW THE FLOW TO RESET TO THE REGISTRATION STEP FOR THE FIRST TAB
        if (query.get("success") === "false") {
          connection?.invoke("SendFailureMessage", idNumber, "ID VERIFICATION FAILED")
            .then(x => {
              console.log("sendFailureMessage done", x);
            })
            .catch(e => {
              console.error("invoking SendFailureMessage failed. restart registration", e);
            });
        }
        else if (query.get("success") === "true") {
          //close the window on success
          window.close();
        }
        //start listing to events
        // if (connection) {
        //   registerEventListener(connection)
        // }
      })
      .catch((e) => {
        console.log(e)
      });
    // }
  }, []);

  useEffect(() => {
    if (retryCount < configurations.MaxIdRetries) {
      console.log("retry value less: ", retryCount);
      setShowRetryNotification(true);
    } else {
      setShowRetryNotification(false);
      console.log("retry value more: ", retryCount);
    }
  }, [retryCount]);

  const setUpConnection = async () => {
    const connection = new SignalR.HubConnectionBuilder()
      .withUrl(configurations.signalrHubAddress!)
      // .configureLogging(SignalR.LogLevel.in)
      .withAutomaticReconnect()
      .build();

    try {
      console.log('attempting to start connection; connection status: ', SignalR.HubConnectionState.Connected);
      registerEventListener(connection);

      await connection.start().then(a => { }).catch(x => console.log("conn start", x));
      console.log("connection id", connection.connectionId)
      return connection;

    } catch (err) {
      console.log(err);
    }
  }

  const subscribe = async (personalCode: string) => {
    console.log("entered the subscribe method", connectionRef.current)
    if (connectionRef.current?.state != SignalR.HubConnectionState.Connected) {
      console.log("subscibe - connection details ", connectionRef.current?.state)
      return;
    }
    await connectionRef?.current?.invoke("Subscribe", personalCode).then((data) => { //TODO: CHANGE TO ASYNC AWAIT 
      console.log("subscribe - invoked", data)
    }).catch((e) => { console.error("subscibe - reason", e) });
  }

  const invokeIdMismatch = async () => {
    console.log("entered the invokeIdMismatch method", connectionRef.current)
    if (connectionRef.current?.state != SignalR.HubConnectionState.Connected) {
      console.log("invokeIdMismatch - connection details ", connectionRef.current?.state)
      return;
    };

    //create message
    const message: IdentificationData = {
      failReason: ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationIdMismatched.Content,
      status: IdVerificationRegistrationStatus.FAILED
    }

    //remove id Verification Id
    localStorage.removeItem("idVerificationId");

    await connectionRef?.current?.invoke(
      "SendMessageIdDetailsMismatchedToGroup",
      idVerificationId,
      message
    ).then((data) => {
      console.log("SendMessageIdDetailsMismatchedToGroup - invoked", data)
    }).catch((e) => { console.error("SendMessageIdDetailsMismatchedToGroup - reason", e) });
  }

  //invoke onFailure on server
  const sendFailureMessage = async (personalCode: string, message: string) => {
    if (connectionRef.current?.state != SignalR.HubConnectionState.Connected) {
      return;
    }
    await connectionRef?.current?.invoke("SendFailureMessage", personalCode, message).then((data) => {
    });
  }

  const registerEventListener = async (connection: SignalR.HubConnection) => {

    connection.on("OnConnected", (payload) => {
      console.log("SignalR connection established");
      // subscribe(idNumber);
      console.log("connection id", connection.connectionId)
    });

    connection.on("OnUpdatedCustomer", (payload) => {
      console.log("customer details verified: ", payload);
      let documentData = payload.documentData;
      setRequestStatus(payload.identificationData.status);
      setRegistration((prevState) => {
        let newState = prevState;
        newState.FirstName = documentData.firstName;
        newState.MiddleName = documentData.middleName;
        newState.LastName = documentData.lastName;
        newState.DateOfBirth = documentData.dateOfBirth;
        newState.Parish = documentData.address;
        newState.IdType = documentData.documentType; //should fetch against the dropdownlist
        newState.IdNumber = documentData.documentNumber;
        newState.IdExpiryDate = documentData.dateOfExpiration;
        newState.StreetAddress = documentData.address;
        return newState;
      });
    });

    connection.on("OnIdentificationFail", (payload) => {
      console.log("customer details not verified: ", payload);
      setRequestStatus(payload.status);
      setShowNotification(true);
      incrementRetryCount();
    });

    connection.on("OnIdMismatch", (payload) => {
      console.log("customer details verified, but not matched: ", payload);
      setRequestStatus(payload.status);
      // setShowNotification(true);
      // setShowIdMismatchNotification(true);    
    });

    connection.on("onFailure", (payload) => {
      console.error("registration failed", payload);
      setRequestStatus(IdVerificationRegistrationStatus.FAILED);
    });
  }

  const clearRequestStatus = async () => {
    setRequestStatus("");
  }

  return (
    <SignalRContext.Provider value={{ startConnection, getRegistrationData, getRequestStatus, subscribe, connectionRef, sendFailureMessage, clearRequestStatus, invokeIdMismatch }}>
      {children}
      {showNotification && showRetryNotification
        ? <NotificationLayer showErr={showNotification} setShowErr={setShowNotification} cardType={MessageCardType.Warning} message={Errors[0]} />
        : <NotificationLayer showErr={showNotification} setShowErr={setShowNotification} cardType={MessageCardType.Warning} message={Errors[2]} />
      }
      {showIdMismatchNotification
        ?
        <NotificationLayer showErr={showNotification} setShowErr={setShowNotification} cardType={MessageCardType.Warning} message={Errors[1]} />
        : <></>}
    </SignalRContext.Provider>
  );

};
