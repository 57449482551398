const BASE_BUTTON_STYLE = `
font-bold rounded-full 
border-2 border-solid border-cyan 
text-cyan text-lg focus:outline-none
focus:shadow-outline
`;

const BUTTON_STYLE = `
font-bold rounded-full h-8 px-3
border-2 border-solid border-cyan 
text-cyan text-sm focus:outline-none
focus:shadow-outline
`;

const MOBILE_BUTTON_STYLE = `
${BASE_BUTTON_STYLE} w-full py-2
md:w-56 md:py-1 

`;

type Props = {
  label: string;
  icon?: any;
  disabled?: boolean;
  action: (e: React.SyntheticEvent) => void;
};

const InlineButton = ({ label, icon, disabled, action }: Props) => {
  return (
    <span className="flex text-cyan">
      {icon}
      <button
        className="font-bold rounded-full 
                border-2 border-solid border-cyan 
                text-cyan text-base focus:outline-none
                focus:shadow-outline py-2
                w-56 md:py-1 
                "
        disabled={disabled}
        onClick={action}
      >
        {label}
      </button>
    </span>
  );
};

export { InlineButton };
