import React, { useState } from "react";
import type { WizardStep } from "../Wizard";
import Stage from "./Stage";

function BaseProgressBar({
  stage,
  stages,
  className,
}: {
  stage: WizardStep;
  stages: WizardStep[];
  className?: string | undefined;
}) {
  const [farthest, setFarthest] = useState(0);

  const getStageList = () => {
    let state = "inactive";
    let stageDOM = null;
    return stages.map(function (proStage, index) {
      if (proStage === stage) {
        state = "active";
      }
      stageDOM = (
        <>
          {proStage.isStageOptionClickable !== undefined && proStage.isStageOptionClickable !== null && proStage.isStageOptionClickable === true?
            <Stage url={proStage.url} key={index} state={state}>
              {proStage.name}
            </Stage>
            :
            <Stage key={index} state={state}>
              {proStage.name}
            </Stage>
          }
        </>
      );
      if (state === "active") {
        state = "inactive";
      }
      if (index >= farthest) {
        state = "disabled";
      }
      return stageDOM;
    });
  };

  const checkFarthest = () => {
    stages.forEach((element, index) => {
      if (element === stage && index > farthest) {
        setFarthest(index);
        return;
      }
    });
  };

  const stageList = getStageList();
  checkFarthest();
  return (
    <div className={`w-full mb-6 ${className}`} data-testid="progress-bar">
      <ul className="flex justify-between text-center mb-3 mx-6">
        {stageList}
      </ul>
      <div className="bg-gray-300 h-1 w-full rounded">
        <div
          className={
            "bg-gradient-to-r from-cyan to-fushia h-1 rounded " +
            stages[stages.indexOf(stage)].animation
          }
        ></div>
      </div>
    </div>
  );
}

export default BaseProgressBar;
