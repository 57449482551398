function Avatar({ className }: { className?: string }) {
    return (
        <div className={className}>
            <div className="h-28 w-28 rounded-full flex justify-center items-center bg-gradient-to-r from-seamoss to-neon mb-6 sm:mb-0">
                <div className="h-20 w-20 rounded-full flex justify-center items-center bg-white">
                    <img src="default-avatar.jpg" className="rounded-full h-14 w-14" alt="avatar" />
                </div>            
            </div>
        </div>
    );
}
export default Avatar