import React from "react";
import BaseCard from "./Card";

function TitleCard({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <BaseCard className="px-4 py-2  pb-4 md:p-8 flex-col flex-grow">
      <h1 className="text-navy text-2xl  text-left mb-4 border-b border-solid border-gray-200 pb-1">
        {title}
      </h1>
      <div className="text-md text-gray-600 flex flex-col flex-grow">
        {children}
      </div>
    </BaseCard>
  );
}

export default TitleCard;
