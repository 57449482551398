

function ErrorMessage({errorMessage = undefined}: {errorMessage?: string | string []}) {
    let errorMessageList: string[] | undefined = undefined;
    if (typeof errorMessage === 'string') {
        errorMessageList = [errorMessage]
    } else if (errorMessage) {
        errorMessageList = errorMessage.length > 0 ? errorMessage : undefined
    }
    
    return <>
{ errorMessageList &&  errorMessageList.map((message, index) => <p key={index} className="text-sm text-red-400">{message}</p>) }    </>
}

export default ErrorMessage;
