import { Spinner } from "./Spinner"

export const Loader = ({ children, className, isLoading }:{ children:React.ReactNode, isLoading: boolean, className?: string }) => {
    return (
        <div className="relative">
            { isLoading &&
                <div className={`absolute w-full h-full z-50 flex justify-center items-center ${className}`}>
                        <Spinner />
                </div>
            }
            {children}
        </div>
        
    )
}

