import React from "react";
import type { WizardStep } from "../Wizard";
import MobileProgressBar from "./MobileProgressBar";
import BaseProgressBar from "./ProgressBar";

const ResponsiveProgressBar = ({ stage, stages }: {
    stage: WizardStep;
    stages: WizardStep[];
  }) => {
  return (
    <>
      <MobileProgressBar
        stage={stage}
        stages={stages}
        className={`md:hidden`}
      />
      <BaseProgressBar
        stage={stage}
        stages={stages}
        className={`hidden md:block`}
      />
    </>
  );
};

export { MobileProgressBar, BaseProgressBar };
export default ResponsiveProgressBar;
