import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import Avatar from "../../components/Avatar/Avatar";
import Card from "../../components/Cards";
import { DropDownList, Input, InputGroup } from "../../components/Inputs";
import { IOption } from "../../components/Inputs/DropDownList";
import { Loader } from "../../components/Loaders";
import { ERROR_FORM } from "../../config/constants";
import { ApplicationContext } from "../../context/AppContext";
import { WizardContext } from "../../context/WizardContext";
import API from "../../services/ApiService";
import ErrorMessages from "../../config/ErrorMessageConfiguration.json";
import IdTypes from "../../config/IdTypeConfiguration.json";
import RequiredFieldLength from "../../config/RequiredFieldLengthConfiguration.json";
import WarningMessages from "../../config/WarningMessageConfiguration.json";
import RegistrationActivationMessages from "../../config/RegistrationActivationConfiguration.json";
import { useCancelToken } from "../../components/hooks/useCancelToken";
import AdditionalFormField from "../../components/Form/AdditionalFormField";
import { DocumentData, IdTypeMapping, IdVerificationRegistrationStatus } from "../../services/configs";
import { SignalRContext } from "../../context/SignalRContext";
import WizardMessages from '../../config/WizardConfiguration.json'
import { LargeSpinner } from "../../components/Spinners/LargeSpinner";
import { usePersistentStorageValue } from "../../components/hooks/usePersistentStorageValue";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../components/hooks/useQuery";
import { ErrorMessageCongiguraton } from "../../config/ErrorMessagesConfiguration";
import { getSelectedIdType, isIdTypeMismatched } from "../../helpers/SimRegistrationActivationHandler";
import { MessageCardType } from "../../components/Cards/MessageCard";

enum SimRegistrationWizardAction {
    none,
    checkSimQuota,
    additionalFormFields,
    idVerification
}

function SimRegistrationActivation({ isTermsAndConditionAccepted }: { isTermsAndConditionAccepted: boolean }) {
    const { setAction, setIsValid } = useContext(WizardContext);
    const { showAppError } = useContext(ApplicationContext);
    const { getRegistrationData, getRequestStatus, startConnection, connectionRef, subscribe, clearRequestStatus, invokeIdMismatch } = useContext(SignalRContext);

    const [showIdDetailsSection, _setShowIdDetailsSection] = useState(true);
    const [showWarningSection, setShowWarningSection] = useState(false);
    const [showAdditionalFormSection, _setShowAdditionalFormSection] = useState(false);
    const [showActivationSection, _setShowActivationSection] = useState(false);
    const [wizardAction, _setWizardAction] = useState(SimRegistrationWizardAction.checkSimQuota);

    // const [idNumber, setIdNumber] = useState('');
    const [idNumber, setIdNumber] = useState(() => {

        const found = localStorage.getItem('idNumber');
        const id = found ? JSON.parse(found) : '';

        return id;
    });
    const [idType, setIdType] = useState<number | undefined>(undefined);
    const [idNumberErrorMessage, setIdNumberErrorMessage] = useState<string[]>([]);
    const [idTypeErrorMessage, setIdTypeErrorMessage] = useState<string | undefined>(undefined);

    const [idTypeOptions, setIdTypeOptions] = useState([{ name: '' } as IOption])
    const [isIdDetailsLoading, setIsIdDetailsLoading] = useState(true)
    const [isIdentificationDetailsDisabled, setIsIdentificationDetailsDisabled] = useState(false)
    // const [idVericationLoading, setIdVericationLoading] = useState(false);
    const [idVericationLoading, setIdVericationLoading] = useState(() => {

        const found = localStorage.getItem('idVerificationStarted');
        const isLoading = found ? JSON.parse(found) : false;

        return isLoading;
    });
    const shouldCheckFormIdDetailsValidation = useRef(false);
    const { getCancelToken } = useCancelToken();

    const [documentData, setdocumentData] = useState<DocumentData | undefined>(undefined);
    const [idVerificationStarted, setIdVerificationStarted] = usePersistentStorageValue("idVerificationStarted", false);
    const [userData, setUserData] = usePersistentStorageValue<DocumentData | undefined>("userData", undefined);
    const [timeOutValue, setTimeOutValue] = useState<NodeJS.Timeout>();
    const [termsAndConditionAccepted, setTermsAndConditionAccepted] = usePersistentStorageValue<boolean>("termsAndCondition", isTermsAndConditionAccepted);
    const [preventNextStep, setPreventNextStep] = usePersistentStorageValue<boolean>("preventNextStep", !isTermsAndConditionAccepted);

    const [selectedIdType, setSelectedIdType] = usePersistentStorageValue<number>("idType", IdTypeMapping.NO_MATCH);
    const [showIdMismatchError, setShowIdMismatchError] = useState(false);


    // Identification Details Form Validation
    const identificationDetailsFormValidation = () => {
        if (!idType) {
            return false;
        }
        if (!shouldCheckFormIdDetailsValidation.current) {
            shouldCheckFormIdDetailsValidation.current = true;
            return false;
        }
        let isFormValid = true;

        let idNumberErrorMessages: string[] = []
        //Validation on Id Number
        if (!idNumber) {
            isFormValid = false
            idNumberErrorMessages.push(ErrorMessages.REQUIRED_FIELD_ID_NUMBER);
        } else if (idType && idType === IdTypes.DRIVERS_LICENSE) {
            if (!(/^\+?(0|[1-9]\d*)$/.test(idNumber))) {
                isFormValid = false
                idNumberErrorMessages.push(ErrorMessages.ONLY_DIGITS_DRIVERS_LICENSE);
            } else if (idNumber.length !== RequiredFieldLength.DRIVERS_LICENSE_FIELD_LENGTH) {
                isFormValid = false
                idNumberErrorMessages.push(ErrorMessages.INVALID_DRIVERS_LICENSE_LENGTH);
            }
        } else if (idType && idType === IdTypes.PASSPORT) {
            if ((idNumber.match(/[0-9]/g) || []).length !== RequiredFieldLength.PASSPORT_NUMBER_LENGTH) {
                isFormValid = false
                idNumberErrorMessages.push(ErrorMessages.INVALID_PASSPORT_NUMBER_LENGTH)
            }
            if ((idNumber.match(/[a-zA-z]/g) || []).length !== RequiredFieldLength.PASSPORT_CHARACTER_LENGTH) {
                isFormValid = false
                idNumberErrorMessages.push(ErrorMessages.INVALID_PASSPORT_CHARACTER_LENGTH)
            }
        }
        setIdNumberErrorMessage(idNumberErrorMessages)

        // Validation on Id Type
        if (idType === Number.NEGATIVE_INFINITY) {
            setIdTypeErrorMessage(ErrorMessages.FETCH_ID_TYPES_FAILURE)
            isFormValid = false
        } else {
            setIdTypeErrorMessage(undefined)
        }

        setIsValid(isFormValid)
        return isFormValid;
    }
    useEffect(() => {
        identificationDetailsFormValidation();
    }, [idNumber, idType]);

    useEffect(() => {

        console.log("idVerificationStarted", idVericationLoading)

        // setIdVericationLoading(idVerificationStarted ? idVerificationStarted : false);
        setIdVericationLoading(idVerificationStarted);

        //resubscribe
        const resubscribe = async () => {
            console.log("conn 2", connectionRef?.current)
            subscribe(idNumber);
        }

        const timeoutId = setTimeout(() => {
            resubscribe().catch(x => {
                console.error("resubscribe failed", x)
            });
        }, 1000);

        return () => {
            clearTimeout(timeoutId);
        }

    }, []);

    const query = useQuery();

    const navigate = useNavigate();
    useEffect(() => {
        if (query.get("success") === "true") {
            console.log("success is true")
            //close the window on success
            window.close();
        }
        else if (query.get("success") === "false") {

            console.log("success was not true")

            //clear all localStarage values that inat indicates that id verification has started
            localStorage.removeItem("idNumber");
            localStorage.removeItem("userData");

            //set localStorage value of idVerificationStarted to false
            setIdVerificationStarted(false);

            //redirect to registration to restart id verification process
            //   navigate(`/`);
            navigate(`/registration`);

            //show notification that states that the registraion process failed
            showAppError(ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationFailed.Title, ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationFailed.Content);
        }


    }, [])


    // useEffect(() => {
    //     localStorage.setItem('idVerificationStarted', JSON.stringify(idVericationLoading));
    //     console.log("idVerificationStarted", idVericationLoading)
    // }, [idVericationLoading]);

    // Set the Action of the Submit button


    //#region  on registration status changed methods
    const resetCommonValuesOnStatusUpdate = () => {
        //set loading state to for to false id verification loading process after id check
        setIdVericationLoading(false);
        setIdVerificationStarted(false);

        //reset request status
        clearRequestStatus();

        //clear timeout to reload screen
        clearTimeout(timeOutValue);
    }


    function setIdVerificationTimeout(timeoutValue: number) {
        const timeout = setTimeout(() => {
            localStorage.removeItem('idVerificationStarted');

            //remove id verification id
            localStorage.removeItem('idVerificationId');

            //reset the selected id type
            setSelectedIdType(IdTypeMapping.NO_MATCH);

            window.location.reload();
            showAppError(ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationTimeout.Title, ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationTimeout.Content);
        }, timeoutValue); //Default 5 minutes

        setTimeOutValue(timeout);
    }


    const onRegistrationApproved = () => {
        const isIdMismatched = isIdTypeMismatched(idNumber, selectedIdType, getRegistrationData()?.IdNumber, getRegistrationData()?.IdType);
        if (isIdMismatched) {
            invokeIdMismatch()
            setShowIdMismatchError(true);
        }
        else {
            setUserData({
                FirstName: getRegistrationData()?.FirstName ?? "",
                MiddleName: getRegistrationData()?.MiddleName ?? "",
                LastName: getRegistrationData()?.LastName ?? "",
                DateOfBirth: getRegistrationData()?.DateOfBirth ?? "",
                Nationality: "",
                Gender: "",
                Address: getRegistrationData()?.StreetAddress ?? "",
                Country: "",
                DocumentNumber: getRegistrationData()?.IdNumber ?? "",
                DocumentType: getRegistrationData()?.IdType ?? "",
                IdExpirartionDate: getRegistrationData()?.IdExpiryDate.toString() ?? "",
                PersonalIdentityCode: 0,
                DateOfIssue: new Date().toString()
            });

            setdocumentData({
                FirstName: getRegistrationData()?.FirstName ?? "",
                MiddleName: getRegistrationData()?.MiddleName ?? "",
                LastName: getRegistrationData()?.LastName ?? "",
                DateOfBirth: getRegistrationData()?.DateOfBirth ?? "",
                Nationality: "",
                Gender: "",
                Address: getRegistrationData()?.StreetAddress ?? "",
                Country: "",
                DocumentNumber: getRegistrationData()?.IdNumber ?? "",
                DocumentType: getRegistrationData()?.IdType ?? "",
                IdExpirartionDate: getRegistrationData()?.IdExpiryDate.toString() ?? "",
                PersonalIdentityCode: 0,
                DateOfIssue: new Date().toString()
            });

            resetCommonValuesOnStatusUpdate();

            //update wizard action
            _setWizardAction(SimRegistrationWizardAction.additionalFormFields);

            //update states that tell what view to render
            _setShowIdDetailsSection(false);
            _setShowAdditionalFormSection(true);
        }
    }


    const selectRegistrationFailedErrorMessage = () => {
        if (showIdMismatchError) {
            showAppError(ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationIdMismatched.Title,
                ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationIdMismatched.Content,
                MessageCardType.Default,
                ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationIdMismatched.Duration
            );
        }
        else {
            showAppError(ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationFailed.Title, ErrorMessageCongiguraton.IdVerification.CreditBureau_IdVerificationFailed.Content);
        }
    }

    const onRegistrationFailed = () => {
        resetCommonValuesOnStatusUpdate();

        //set show idDetail to true to show section to select id type and enter id number
        _setShowIdDetailsSection(true);

        //re-enable the idDetail form
        setIsIdentificationDetailsDisabled(false);

        //set show state to show registration form to false
        _setShowAdditionalFormSection(false);

        //reset the selected id type
        setSelectedIdType(IdTypeMapping.NO_MATCH);

        //remove idNumber
        localStorage.removeItem("idNumber");

        //remove user data
        localStorage.removeItem("userData");

        selectRegistrationFailedErrorMessage();
    }
    //#endregion


    useEffect(() => {
        if (wizardAction === SimRegistrationWizardAction.checkSimQuota) {
            setAction(() => () => new Promise<{ redirect: string } | undefined>(async (resolve, reject) => {
                const isIdDetailsFormValid = identificationDetailsFormValidation()
                setIsIdDetailsLoading(true)
                if (isIdDetailsFormValid) {
                    await API.checkSimQuota({ idNumber: idNumber, idType: idType! }).then(data => {
                        if (data.isQuotaExceeded) {
                            setIsValid(false);
                            setShowWarningSection(true)
                            reject(ERROR_FORM);
                        } else {
                            setShowWarningSection(false)
                            setIsIdentificationDetailsDisabled(true)
                            resolve({ redirect: WizardMessages.SECOND_STEP_LINK });

                            //start signalR connection
                            startConnection(idNumber);
                            _setWizardAction(SimRegistrationWizardAction.idVerification);
                            _setShowIdDetailsSection(false);

                            //set idNumber to localStorage
                            localStorage.setItem('idNumber', JSON.stringify(idNumber));

                            //set selected id type
                            setSelectedIdType(idType ?? IdTypeMapping.NO_MATCH);


                            //set loading state to true for id verification loading process after id check
                            setIdVericationLoading(true);
                            setIdVerificationStarted(true);

                            //Set timeout for screen after x amount of minutes/seconds
                            const timeoutValue = parseInt(process.env.REACT_APP_ID_VERIFICATION_TIMEOUT_VALUE ?? "300000")
                            setIdVerificationTimeout(timeoutValue);

                        }
                    }).catch(_ => {
                        showAppError(ErrorMessages.ERROR_MESSAGE_TITLE, ErrorMessages.FETCH_SIM_QUOTA_CHECK_FAILURE)
                        reject(ERROR_FORM);
                    })
                }
                setIsIdDetailsLoading(false)
            }));
        }
        else if (getRequestStatus() === IdVerificationRegistrationStatus.APPROVED) {
            onRegistrationApproved();
        }
        else if (getRequestStatus() === IdVerificationRegistrationStatus.FAILED) {
            onRegistrationFailed();
        }


    }, [wizardAction, idNumber, idType, getRequestStatus(), getRegistrationData()?.FirstName, userData, idVerificationStarted]);

    // On Page Load
    useEffect(() => {
        setIsValid(true);
        API.fetchIdTypes(getCancelToken()).then(value => {
            if (!value || value.length === 0) {
                showAppError(ErrorMessages.ERROR_MESSAGE_TITLE, ErrorMessages.FETCH_ID_TYPES_FAILURE)
            }
            else {
                setIdTypeOptions(value.map(v => ({ name: v.name, id: v.id })))
                setIdType(value[0].id)
            }
        }).catch(_ => {
            setIdType(Number.NEGATIVE_INFINITY)
            showAppError(ErrorMessages.ERROR_MESSAGE_TITLE, ErrorMessages.FETCH_ID_TYPES_FAILURE)
        }).finally(() => {
            setIsIdDetailsLoading(false)
        });
    }, []);

    return (
        <>
            {(idVerificationStarted && documentData === undefined) ? (<div className="h-16 m-auto p-12 relative w-16"><div className="absolute h-full w-full"><LargeSpinner className="h-16 w-16" /></div></div>) : null}

            {/* Identification Details */}
            {showIdDetailsSection && !idVerificationStarted &&
                <Loader isLoading={isIdDetailsLoading} className="rounded-md">
                    <Card className="p-6 flex-col ">
                        <h1 className="text-navy text-2xl text-left mb-4 border-b border-solid border-gray-200 pb-1 font-medium">
                            {RegistrationActivationMessages.IDENTIFICATION_DETAILS_TITLE}
                        </h1>

                        <div className="p-10 flex flex-wrap justify-center">
                            <Avatar className="pr-5" />
                            <InputGroup className="flex flex-wrap justify-between grow">
                                <DropDownList disabled={isIdentificationDetailsDisabled} id="id-type" label={RegistrationActivationMessages.ID_TYPE_LABEL} errorMessage={idTypeErrorMessage} options={idTypeOptions} value={idType} onChange={(event: SyntheticEvent<HTMLSelectElement, Event>) => setIdType(parseInt(event.currentTarget.value))} />
                                <Input disabled={isIdentificationDetailsDisabled} id="id-number" label={RegistrationActivationMessages.ID_NUMBER_LABEL} px="px-3" errorMessage={idNumberErrorMessage} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIdNumber(event.currentTarget.value)} type="text" />
                            </InputGroup>
                        </div>
                    </Card>
                </Loader>
            }

            {/* Warning Section */}
            <Card className={`flex-col relative transition-all ease-in-out duration-700 ${showWarningSection ? "opacity-100 scale-y-100 origin-top max-h-96" : "opacity-0 scale-y-0 origin-top max-h-0"}`}>
                <div className="absolute left-0 bg-red-500 h-full w-3"></div>
                <div className="px-20 pt-12 pb-7 ">
                    <h1 className="text-red-500 pb-5 text-xl font-medium">{WarningMessages.WARNING_TITLE}</h1>
                    <p className="h-96 overflow-y-auto">
                        {WarningMessages.SIM_QUOTA_EXCEED}
                        <img className="px-10 py-5" src="/sim_limit.png" alt="" />
                    </p>
                </div>

            </Card>

            {/* Additional Form Section */}
            {showAdditionalFormSection && !idVerificationStarted && documentData && (<AdditionalFormField isTermsAndConditionAccepted={isTermsAndConditionAccepted} idTypeNumber={idType ? idType : 0} documentData={documentData ?? {}}
            // submitButtonClicked={submitButtonClicked} 
            // clearSubmitButton={clearSubmitButton}
            />)
                //     <Card className="p-6 flex-col">
                //         <h1 className="text-navy text-2xl text-left mb-4 border-b border-solid border-gray-200 pb-1">
                //             Registration Details

                //         </h1>

                //         <div className="p-10">
                //             <InputGroup>
                //                 <Input id="first-name" label="First Name" value="Naida" type="text" disabled={true} />
                //                 <Input id="last-name" label="Last Name" value="Powell" type="text" />
                //             </InputGroup>

                //             <InputGroup>
                //                 <Input id="dob" label="Date of Birth" type="text" />
                //                 <DropDownList id="id-type" label="Id Type" options={idTypeOptions} disabled />
                //             </InputGroup>

                //             <InputGroup>
                //                 <Input id="id-number" label="Id Number" type="text" />
                //                 <Input id="id-expiry-date" label="Id Expiry Date" type="text" />
                //             </InputGroup>

                //             <InputGroup>
                //                 <Input id="trn" label="TRN *" type="text" />
                //                 <Input id="email" label="Email Address *" type="email" />
                //             </InputGroup>

                //             <InputGroup>
                //                 <Input id="street-name" label="Street Name" type="text" width="w-full" />
                //             </InputGroup>

                //             <InputGroup>
                //                 <Input id="city" label="City" type="text" />
                //                 <Input id="parish" label="Parish" type="text" />
                //             </InputGroup>

                //             <InputGroup>
                //                 <Input id="security-questions" label="Security Questions *" type="text" />
                //                 <Input id="security-answers" label="Security Answers *" type="text" />
                //             </InputGroup>
                //         </div>
                //     </Card>
                // }

                // {/* Activation Section */}
                // {showActivationSection &&
                //     <Card className="p-6 flex-col">
                //         <h1 className="text-navy text-2xl text-left mb-4 border-b border-solid border-gray-200 pb-1">
                //             SIM Details
                //         </h1>

                //         <div className="p-10">
                //             <InputGroup>
                //                 <Input id="activation-code" label="Activation Code" type="text" />
                //             </InputGroup>

                //             <InputGroup>
                //                 <Input id="msisdn" label="MSISDN" type="text" />
                //                 <Input id="iccid" label="ICCID" type="text" />
                //             </InputGroup>
                //         </div>
                //     </Card>
            }
        </>
    )
}

export default SimRegistrationActivation;