import { FunctionComponent, SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import Card from "../Cards";
import { DropDownList, Input, InputGroup } from "../Inputs";
import { Spinner } from "../Spinners/Spinner";
import { ApplicationContext } from "../../context/AppContext";
import API, { GetCountriesRequest, GetRegionRequest, GetSecurityQuestionsRequest, GetSimDetailRequest, SimPackage, SubmitRegistrationRequest } from "../../services/ApiService";
import { DocumentData, IdentificationDocumentType, UserRegistration } from "../../services/configs";
// import errorConfig from "../../config/ErrorMessagesConfiguration.json";
import ActivationCodeConfigs from "../../config/ActivationCodeConfiguration.json";
import { LargeSpinner } from "../Spinners/LargeSpinner";
import { format } from 'date-fns';
import { ErrorMessageCongiguraton } from '../../config/ErrorMessagesConfiguration'
import { InformationMessagesConfiguration } from '../../config/InformationMessagesConfiguration'
import { SuccessMessagesConfiguration } from '../../config/SuccessMessagesConfiguration'
import { WarningMessageConfiguration } from '../../config/WarningMessageConfiguration'
import Avatar from "../Avatar/Avatar";
import { IOption } from "../Inputs/DropDownList";
import { WizardContext } from "../../context/WizardContext";
import WizardMessages from "../../config/WizardConfiguration.json"
import { ERROR_FORM, HTTP_STATUS_404 } from "../../config/constants";
import { AxiosError } from "axios";
import { redirect, useNavigate } from "react-router-dom";
import { WIZARD_STEP } from "../Wizard/Wizard";
import { MessageCardType } from "../Cards/MessageCard";
import { ActivationCodeConfiguration } from "../../config/ActivationCodeConfiguration";

interface AdditionalFormFieldProps {
    documentData?: DocumentData;
    // submitButtonClicked: boolean;
    idTypeNumber: number;
    isTermsAndConditionAccepted: boolean;
    // clearSubmitButton: Function;
}

enum FieldType {
    firstName = 'firstName',
    lastName = 'lastName',
    dateOfBirth = 'dateOfBirth',
    documentType = 'documentType',
    documentNumber = 'documentNumber',
    trn = 'trn',
    IdExpirartionDate = 'IdExpirartionDate',
    emailAddress = 'emailAddress',
    streetAddress = 'streetAddress',
    city = 'city',
    country = 'country',
    parish = 'parish',
    securityQuestion = 'securityQuestion',
    customSecurityQuestion = 'customSecurityQuestion',
    securityAnswer = 'securityAnswer',
    activationCode = 'activationCode',
    msisdn = 'msisdn',
    iccid = 'iccid'
}

interface Validation {
    id: string,
    message: string
}



// const AdditionalFormField: FunctionComponent<AdditionalFormFieldProps> = ({ documentData, submitButtonClicked, idTypeNumber, isTermsAndConditionAccepted, clearSubmitButton }) => {
const AdditionalFormField: FunctionComponent<AdditionalFormFieldProps> = ({ documentData, idTypeNumber, isTermsAndConditionAccepted }) => {
    const [activationAttempts, setActivationAttempts] = useState<number>(0);
    const [activationCode, setActivationCode] = useState<string>();
    const [simDetail, setSimDetail] = useState<SimPackage[]>();
    const [regions, setRegions] = useState<IOption[]>([{ id: 0, name: "" }]);
    const [securityQuestions, setSecurityQuestions] = useState<IOption[]>([{ id: 0, name: "" }]);
    const [countries, setCountries] = useState<IOption[]>([{ id: 0, name: "" }]);
    const [validationMessages, setValidationMessages] = useState<Validation[]>([]);
    const [formLoading, setFormLoading] = useState(false);
    const [simDetailLoading, setSimDetailLoading] = useState(false);
    const [isCustomSecurityQuestion, setIsCustomSecurityQuestion] = useState(false);
    const [idTypeName, setIdTypeName] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const [registration, setRegistration] = useState<UserRegistration>({
        FirstName: documentData?.FirstName ?? "",
        MiddleName: "",
        LastName: documentData?.LastName ?? "",
        Gender: documentData?.Gender ?? "",
        PersonalIdentityCode: documentData?.PersonalIdentityCode ?? 0,
        Nationality: documentData?.Nationality ?? "",
        Country: documentData?.Country ?? "",
        DateOfIssue: documentData?.DateOfIssue ?? "",
        DateOfBirth: documentData?.DateOfBirth ?? "",
        IDTypeId: idTypeNumber,
        IDNumber: documentData?.DocumentNumber ?? "",
        Trn: documentData?.DocumentType == IdentificationDocumentType.DRIVER_LICENSE ? documentData?.DocumentNumber : "",
        IdExpirartionDate: documentData?.IdExpirartionDate ?? "new Date()",
        Email: "",
        Address: "",
        City: "",
        RegionId: 0,
        SecurityQuestionId: 0,
        CustomSecurityQuestion: "",
        SecurityAnswer: "",
        ActivationCode: "",
        TermsAndConAccepted: isTermsAndConditionAccepted,
        ActivationCodeValid: false,
    });
    const [countryId, setCountryId] = useState(0)
    const { setAction, setIsValid } = useContext(WizardContext);
    const { showAppError } = useContext(ApplicationContext)
    const [disableConfirmButton, setDisableConfirmButton] = useState(true);

    //#region get country & security questions
    useEffect(() => {

        //#region get countries
        let countryRequest: GetCountriesRequest = {}
        setFormLoading(true);
        API.getCountries(countryRequest).then((response) => {

            const countryList: IOption[] = [{ id: 0, name: "Select a Country" }];
            if (response && response.data) {
                response.data.map(region => {
                    countryList.push(region);
                });

                setCountries(countryList);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setFormLoading(false);
        })
        //#endregion

        let securityQuestioRequest: GetSecurityQuestionsRequest = {
        }
        setFormLoading(true);
        API.getSecurityQuestions(securityQuestioRequest).then((response) => {
            const questions: IOption[] = [{ id: 0, name: "Select a Security Question" }];
            if (response && response.data) {
                console.log(response);

                response.data.map(question => {
                    questions.push(question);
                });

                setSecurityQuestions(questions);
                //set default security question id
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.SecurityQuestionId = questions[0].id ?? 0;
                    return newState;
                });
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setFormLoading(false);
        })
        //#endregion

    }, []);

    //#region get regions
    useEffect(() => {
        //#region get region
        let regionRequest: GetRegionRequest = {
            regionNumber: countryId
        }
        //Check if a country was selected
        if (countryId === 0) {
            const Regions: IOption[] = [{ id: 0, name: "-Select Country-" }];
            setRegions(Regions);
        }
        else {
            setFormLoading(true);
            API.getRegions(regionRequest).then((response) => {

                const Regions: IOption[] = [{ id: 0, name: "Select a Region" }];
                if (response && response.data) {
                    response.data.map(region => {
                        Regions.push(region);
                    });

                    setRegions(Regions);

                    //set default region id
                    setRegistration((currentState) => {
                        let newState = Object.assign({}, currentState);
                        newState.RegionId = Regions[0].id ?? 0;
                        return newState;
                    });
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setFormLoading(false);
            })
        }

        //#endregion
    }, [countryId]);

    //#endregion

    //set registration state to exclude middle name from firstname 
    useEffect(() => {

        if (!!documentData?.FirstName && documentData?.DocumentType === IdentificationDocumentType.DRIVER_LICENSE || !!documentData?.FirstName && IdentificationDocumentType.PASSPORT) {
            const firstAndMiddleName = documentData?.FirstName?.split(" ");

            const firstName = firstAndMiddleName[0]?.trim();
            //accept all name after the first
            const middleName = firstAndMiddleName.length >= 2 ? firstAndMiddleName.splice(1).join(" ") : "";

            setRegistration((currentState) => {
                let newState = Object.assign({}, currentState);
                newState.FirstName = firstName ?? "";
                newState.MiddleName = middleName ?? "";
                return newState;
            });
        }
    }, []);
    const clearValidationByFieldName = (name: string) => {
        //clear validations
        setValidationMessages((current) =>
            current.filter((val) => {
                return val.id !== name;
            })
        );
    }

    const getCustomSecurityQuestionId = (securityQuestions: IOption[]) => {
        //0 triggers validation
        let questionId = 0;
        const foundId = securityQuestions?.find(x => x.name.toLowerCase() === "other")?.id;
        if (foundId && foundId !== 0) {
            questionId = foundId;
        }

        return questionId;
    }

    //#region handleInputChange
    const handleInputChange = (e: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>) => {
        e.preventDefault();
        setIsValid(true);
        console.log("e", e.currentTarget.value)
        console.log("e name", e.currentTarget.name)
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        switch (name) {
            case FieldType.activationCode: {
                setDisableConfirmButton(false);
                setActivationCode(value);
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.ActivationCode = value;
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.activationCode)
                break;
            }
            case FieldType.firstName: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.FirstName = value;
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.firstName)
                break;
            }
            case FieldType.lastName: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.LastName = value;
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.lastName)
                break;
            }
            case FieldType.dateOfBirth: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.DateOfBirth = new Date(value).toString();
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.dateOfBirth)
                break;
            }
            case FieldType.documentType: {
                // setRegistration((currentState) => {
                //     let newState = Object.assign({}, currentState);
                //     newState. = value;
                //     return newState;
                // });
                setIdTypeName(value)

                //clear validations
                clearValidationByFieldName(FieldType.documentType)
                break;
            }
            case FieldType.documentNumber: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.IDNumber = value;
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.documentNumber)
                break;
            }
            case FieldType.IdExpirartionDate: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.IdExpirartionDate = new Date(value).toString();
                    return newState;
                });
                //clear validations
                clearValidationByFieldName(FieldType.IdExpirartionDate)
                break;
            }
            case FieldType.trn: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.Trn = value;
                    return newState;
                });

                //clear required message
                if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.TrnIsRequired).length !== 0) {
                    setValidationMessages((current) =>
                        current.filter((val) => {
                            return val.message !== ErrorMessageCongiguraton.AdditionalFormFields.TrnIsRequired;
                        })
                    );
                }

                if (!validateTrn(value)) {
                    //   clear validations
                    clearValidationByFieldName(FieldType.trn)
                }
                break;
            }
            case FieldType.emailAddress: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.Email = value;
                    return newState;
                });

                //check for valid email while typing
                if (!validateEmail(value)) {
                    //clear validations
                    clearValidationByFieldName(FieldType.emailAddress)

                }

                //clear required message
                if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.EmailIsRequired).length !== 0) {
                    setValidationMessages((current) =>
                        current.filter((val) => {
                            return val.message !== ErrorMessageCongiguraton.AdditionalFormFields.EmailIsRequired;
                        })
                    );
                }
                break;
            }
            case FieldType.streetAddress: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.Address = value;
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.streetAddress)
                break;
            }
            case FieldType.city: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.City = value;
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.city)
                break;
            }
            case FieldType.country: {

                setCountryId(parseInt(value))

                //reset region id when country is updated
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.RegionId = 0;
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.country)
                clearValidationByFieldName(FieldType.parish)
                break;
            }
            case FieldType.parish: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.RegionId = parseInt(value);
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.parish)
                break;
            }
            case FieldType.securityQuestion: {
                if (parseInt(value) === getCustomSecurityQuestionId(securityQuestions)) {
                    setIsCustomSecurityQuestion(true);
                }
                else {
                    setIsCustomSecurityQuestion(false);
                }

                //set state
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.SecurityQuestionId = parseInt(value);
                    newState.CustomSecurityQuestion = ""; //reset custom security field
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.securityQuestion);
                clearValidationByFieldName(FieldType.customSecurityQuestion);
                break;

            }
            case FieldType.customSecurityQuestion: {

                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.CustomSecurityQuestion = value;
                    newState.SecurityQuestionId = getCustomSecurityQuestionId(securityQuestions);
                    return newState;
                });

                //clear validations
                clearValidationByFieldName(FieldType.securityQuestion);
                clearValidationByFieldName(FieldType.customSecurityQuestion);
                break;

            }
            case FieldType.securityAnswer: {
                setRegistration((currentState) => {
                    let newState = Object.assign({}, currentState);
                    newState.SecurityAnswer = value;
                    return newState;
                });
                //clear validations
                clearValidationByFieldName(FieldType.securityAnswer)
                break;
            }

            default: {
                break;
            }
        }
    }
    //#endregion

    //#region sim details
    const getSimDetails = (activationCodeVal: string) => {
        let request: GetSimDetailRequest = {
            activationCode: activationCode ?? ""
        };

        if (activationCodeVal.trim() !== "" && activationCodeVal.trim().length > 0) {
            showAppError(
                InformationMessagesConfiguration.AdditionalFormFields.GetSimDetailNotificationLoadingMessage.Title,
                InformationMessagesConfiguration.AdditionalFormFields.GetSimDetailNotificationLoadingMessage.Content,
                MessageCardType.Default,
                InformationMessagesConfiguration.AdditionalFormFields.GetSimDetailNotificationLoadingMessage.Duration
            )
            setSimDetailLoading(true);
            setDisableConfirmButton(true);
            API.getSimDetails(request).then((simDetail) => {
                if (simDetail.status === 200 && Array.isArray(simDetail.data)) {
                    setSimDetail(simDetail.data);
                    setRegistration((current) => {
                        return { ...current, ActivationCodeValid: true }
                    });
                } else {
                    setSimDetail([]);
                    setRegistration((current) => {
                        return { ...current, ActivationCodeValid: false }
                    });
                }

            })
                .catch((err: AxiosError) => {
                    if (err.response?.status === HTTP_STATUS_404) {
                        let attempts = activationAttempts + 1
                        if (attempts == ActivationCodeConfiguration.ACTIVATION_ATTEMPTS) {
                            setRegistration((current) => {
                                return { ...current, ActivationCodeValid: false }
                            });
                            showAppError(ErrorMessageCongiguraton.AdditionalFormFields.ActivationCodeAttemptsExceeded.Title, ErrorMessageCongiguraton.AdditionalFormFields.ActivationCodeAttemptsExceeded.Content, MessageCardType.Default, ErrorMessageCongiguraton.AdditionalFormFields.ActivationCodeAttemptsExceeded.Duration);
                        }
                        else {
                            showAppError(ErrorMessageCongiguraton.AdditionalFormFields.ActivationCodeNotFound.Title, ErrorMessageCongiguraton.AdditionalFormFields.ActivationCodeNotFound.Content, MessageCardType.Default, ErrorMessageCongiguraton.AdditionalFormFields.ActivationCodeNotFound.Duration);
                        }
                        setActivationAttempts(attempts)
                    } else {
                        let errorMsgNotificationContent = undefined;
                        if (ErrorMessageCongiguraton.AdditionalFormFields.SimDetailNotificationError.Content.trim() !== "" && ErrorMessageCongiguraton.AdditionalFormFields.SimDetailNotificationError.Content.trim() !== "undefined") {
                            errorMsgNotificationContent = ErrorMessageCongiguraton.AdditionalFormFields.SimDetailNotificationError.Content;
                        }
                        else if (ErrorMessageCongiguraton.AdditionalFormFields.SimDetailNotificationError.Content.trim() === "") {
                            errorMsgNotificationContent = err.message;
                        }
                        showAppError(ErrorMessageCongiguraton.AdditionalFormFields.SimDetailNotificationError.Title, errorMsgNotificationContent, MessageCardType.Default, ErrorMessageCongiguraton.AdditionalFormFields.SimDetailNotificationError.Duration);
                    }
                    setSimDetail([]);

                }).finally(() => {
                    setSimDetailLoading(false);
                })
        }
        else {
            setSimDetail([]);
            showAppError(ErrorMessageCongiguraton.AdditionalFormFields.NoActivationCode.Title, ErrorMessageCongiguraton.AdditionalFormFields.NoActivationCode.Content, MessageCardType.Default, ErrorMessageCongiguraton.AdditionalFormFields.NoActivationCode.Duration)
        }

    }

    const onGetSimDetails = (e: SyntheticEvent) => {
        e.preventDefault();
        getSimDetails(activationCode ?? "")
    };
    //#endregion sim details

    //#region submit registration

    //#region validate

    const validateTrn = (trn: string) => {
        let validation: { isInValid: boolean, newEl: { id: string, message: string } }[] = new Array();
        var trnPattern = new RegExp("^\\d{9}$");

        if (trn.trim().length > 0 && trn.trim().length !== 9) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.TrnIsLessOrMoreThanNineChars).length === 0
                && ErrorMessageCongiguraton.AdditionalFormFields.TrnIsLessOrMoreThanNineChars !== ErrorMessageCongiguraton.AdditionalFormFields.TrnMustContainsOnlyNumbers
            ) {
                let newEl: { id: string, message: string } = { id: FieldType.trn, message: ErrorMessageCongiguraton.AdditionalFormFields.TrnIsLessOrMoreThanNineChars }
                // setValidationMessages(currentState => [...currentState, newEl]);

                validation.push({ isInValid: true, newEl: newEl });

            }
            else {
                //clear required message
                if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.TrnIsLessOrMoreThanNineChars).length !== 0) {
                    setValidationMessages((current) =>
                        current.filter((val) => {
                            return val.message !== ErrorMessageCongiguraton.AdditionalFormFields.TrnIsLessOrMoreThanNineChars;
                        })
                    );
                }
            }
        }

        if (trn.trim().length > 0 && !trn.trim().match(trnPattern)) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.TrnMustContainsOnlyNumbers).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.trn, message: ErrorMessageCongiguraton.AdditionalFormFields.TrnMustContainsOnlyNumbers }
                // setValidationMessages(currentState => [...currentState, newEl]);

                validation.push({ isInValid: true, newEl: newEl });

            } else {
                //clear required message
                if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.TrnMustContainsOnlyNumbers).length !== 0) {
                    setValidationMessages((current) =>
                        current.filter((val) => {
                            return val.message !== ErrorMessageCongiguraton.AdditionalFormFields.TrnMustContainsOnlyNumbers;
                        })
                    );
                }
            }
        }
        return validation;
    }

    const validateEmail = (email: string) => {
        let validation: { isInValid: boolean, newEl: { id: string, message: string } } | undefined;
        var emailPattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
        if (email.length > 0 && !email.match(emailPattern)) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.EmailIsInvalid).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.emailAddress, message: ErrorMessageCongiguraton.AdditionalFormFields.EmailIsInvalid }
                // setValidationMessages(currentState => [...currentState, newEl]);

                validation = { isInValid: true, newEl: newEl };
            }
        }
        else {
            //clear required message
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.EmailIsInvalid).length !== 0) {
                setValidationMessages((current) =>
                    current.filter((val) => {
                        return val.message !== ErrorMessageCongiguraton.AdditionalFormFields.EmailIsInvalid;
                    })
                );
            }
        }
        return validation;
    }

    // const firstRender = useRef(false);
    // useEffect(() => {
    //     if(!firstRender)
    //   handleValidation();

    // }, []);


    const handleValidation = () => {

        let submitSuccessful = false;
        let newDate = new Date;
        let validationMsg = validationMessages;

        //activation code
        if (!registration.ActivationCode || registration.ActivationCode.trim() === "" || registration.ActivationCode.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.ActivationCodeIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.activationCode, message: ErrorMessageCongiguraton.AdditionalFormFields.ActivationCodeIsRequired }
                validationMsg.push(newEl);
            }
        }

        //firstname
        if (registration.FirstName.trim() === "" || registration.FirstName.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.FirstNameIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.firstName, message: ErrorMessageCongiguraton.AdditionalFormFields.FirstNameIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //lastname
        if (registration.LastName.trim() === "" || registration.LastName.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.LastNameIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.lastName, message: ErrorMessageCongiguraton.AdditionalFormFields.LastNameIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //date of birth
        let dob = new Date(registration.DateOfBirth);
        if (dob.toDateString() === newDate.toDateString()) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.DateOfBirthIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.dateOfBirth, message: ErrorMessageCongiguraton.AdditionalFormFields.DateOfBirthIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //id type
        if (registration.IDTypeId === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.IdTypeIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.documentType, message: ErrorMessageCongiguraton.AdditionalFormFields.IdTypeIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //id number
        if (registration.IDNumber.trim() === "" || registration.IDNumber.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.IDNumberIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.documentNumber, message: ErrorMessageCongiguraton.AdditionalFormFields.IDNumberIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //id expiration date
        let doe = new Date(registration.IdExpirartionDate);
        if (doe.toDateString() === newDate.toDateString()) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.IDExpiryDateIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.IdExpirartionDate, message: ErrorMessageCongiguraton.AdditionalFormFields.IDExpiryDateIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //email address
        if (registration.Email.trim() === "" || registration.Email.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.EmailIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.emailAddress, message: ErrorMessageCongiguraton.AdditionalFormFields.EmailIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //validate email address 
        let emailValidation = validateEmail(registration.Email);
        if (emailValidation) {
            validationMsg.push(emailValidation.newEl);
        }

        //street address
        if (registration.Address.trim() === "" || registration.Address.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.StreetAddressIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.streetAddress, message: ErrorMessageCongiguraton.AdditionalFormFields.StreetAddressIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //city
        if (registration.City.trim() === "" || registration.City.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.CityIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.city, message: ErrorMessageCongiguraton.AdditionalFormFields.CityIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //parish
        if (registration.RegionId === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.RegionIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.parish, message: ErrorMessageCongiguraton.AdditionalFormFields.RegionIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }

        //parish
        if (countryId === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.CountryIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.country, message: ErrorMessageCongiguraton.AdditionalFormFields.CountryIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //security question
        if (registration.SecurityQuestionId === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.SecurityQuestionIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.securityQuestion, message: ErrorMessageCongiguraton.AdditionalFormFields.SecurityQuestionIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }

        //custom security question
        if (registration.SecurityQuestionId === getCustomSecurityQuestionId(securityQuestions) && registration.CustomSecurityQuestion.trim() === "" ||
            registration.SecurityQuestionId === getCustomSecurityQuestionId(securityQuestions) && registration.CustomSecurityQuestion.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.CustomSecurityQuestionIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.customSecurityQuestion, message: ErrorMessageCongiguraton.AdditionalFormFields.CustomSecurityQuestionIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        //security answer
        if (registration.SecurityAnswer.trim() === "" || registration.SecurityAnswer.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.SecurityAnswerIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.securityAnswer, message: ErrorMessageCongiguraton.AdditionalFormFields.SecurityAnswerIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        // trn
        if (registration.Trn.trim() === "" || registration.Trn.trim().length === 0) {
            if (validationMessages.filter(x => x.message === ErrorMessageCongiguraton.AdditionalFormFields.TrnIsRequired).length === 0) {
                let newEl: { id: string, message: string } = { id: FieldType.trn, message: ErrorMessageCongiguraton.AdditionalFormFields.TrnIsRequired }
                // setValidationMessages(currentState => [...currentState, newEl]);
                validationMsg.push(newEl);
            }
        }
        // validate trn
        let trnValidation = validateTrn(registration.Trn);
        if (trnValidation) {
            trnValidation.map((el) => {
                validationMsg.push(el.newEl);
            })
        }

        setValidationMessages(validationMsg);
        return validationMsg
    }
    //#endregion


    //useEffect used to set wizard action; validation and submission of form
    useEffect(() => {
        setAction(() => () => new Promise<{ redirect: string } | undefined>(async (resolve, reject) => {

            //validate form fields
            handleValidation();

            if (validationMessages.length > 0) {
                console.log("has validations errors")
                console.log("state validationMessages", validationMessages)
                setIsValid(false);
                showAppError(WarningMessageConfiguration.AdditionalFormFields.MissingValues.Title, WarningMessageConfiguration.AdditionalFormFields.MissingValues.Content)
            }
            else {
                setFormLoading(true);

                showAppError(InformationMessagesConfiguration.AdditionalFormFields.SubmitFormNotificationLoadingMessage.Title, InformationMessagesConfiguration.AdditionalFormFields.SubmitFormNotificationLoadingMessage.Content)

                //attempt to submit registration
                let request: SubmitRegistrationRequest = { userRegistration: registration };
                API.submitRegistration(request).then((response) => {
                    //TODO: might need to change with correct notification component
                    if (registration.ActivationCodeValid) {
                        showAppError(SuccessMessagesConfiguration.AdditionalFormFields.RegistrationSuccessful.Title, SuccessMessagesConfiguration.AdditionalFormFields.RegistrationSuccessful.Content);
                        localStorage.clear();
                        resolve({ redirect: WizardMessages.THIRD_STEP_LINK });
                    } else {
                        localStorage.clear();
                        navigate('/');
                    }

                })
                    .catch((err) => {
                        //if content is undefined it shows the default message from the notification context
                        if (err && err?.response?.data?.message) {
                            showAppError(ErrorMessageCongiguraton.AdditionalFormFields.FormSubmissiionNotificationError.Title, err.response.data.message);
                        }
                        else {

                            let errorMsgNotificationContent = undefined;
                            if (ErrorMessageCongiguraton.AdditionalFormFields.FormSubmissiionNotificationError.Content.trim() !== "" && ErrorMessageCongiguraton.AdditionalFormFields.FormSubmissiionNotificationError.Content.trim() !== "undefined") {
                                errorMsgNotificationContent = ErrorMessageCongiguraton.AdditionalFormFields.FormSubmissiionNotificationError.Content;
                            }
                            else if (ErrorMessageCongiguraton.AdditionalFormFields.FormSubmissiionNotificationError.Content.trim() === "") {
                                errorMsgNotificationContent = err.message;
                            }

                            showAppError(ErrorMessageCongiguraton.AdditionalFormFields.FormSubmissiionNotificationError.Title, errorMsgNotificationContent);
                        }
                    }).finally(() => {
                        setFormLoading(false);
                        setIsValid(false);
                    })
            }
        }));
    }, [registration, validationMessages])


    const findValidationErrorById = (arr: Validation[], id: string) => {
        let messages: string[] = [];
        arr.filter(x => {
            if (x.id === id) {
                messages.push(x.message)
            }
        })
        return messages
    }

    const getFirstNameFromLicenceOrPassport = (name: string | undefined) => {
        if (!!name && documentData?.DocumentType === IdentificationDocumentType.DRIVER_LICENSE || !!name && IdentificationDocumentType.PASSPORT) {
            const firstName = name?.split(" ");
            return firstName[0];
        }
        return name;
    }
    //#endregion submit registration

    const adjustForTimezone = (dateStr: string) => {
        //get UTC year
        const utcYear = new Date(dateStr).getUTCFullYear();

        //get UTC month
        const utcMonth = new Date(dateStr).getUTCMonth();

        //get UTC Date (day of the month)
        const utcDate = new Date(dateStr).getUTCDate();

        const fixedDate = new Date(utcYear, utcMonth, utcDate);

        //return new date
        return fixedDate;
    }

    //#region custom security question fields
    const displaySecurityQuestionSection = () => {
        const withCutomSecurityQuestion = (
            <>
                <InputGroup>
                    <DropDownList id={FieldType.securityQuestion} label="Security Question *" options={securityQuestions ? securityQuestions : []} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.securityQuestion)} />

                    <Input id={FieldType.customSecurityQuestion} label="Custom Question *" type="text" value={registration.CustomSecurityQuestion} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.customSecurityQuestion)} />
                </InputGroup>

                <InputGroup>
                    <Input id={FieldType.securityAnswer} label="Security Answer *" type="text" value={registration.SecurityAnswer} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.securityAnswer)} />
                </InputGroup>
            </>
        )

        const basicSecurityQuestion = (
            <>
                <InputGroup>
                    <DropDownList id={FieldType.securityQuestion} label="Security Question *" options={securityQuestions ? securityQuestions.map(x => x.name) : []} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.securityQuestion)} />
                    <Input id={FieldType.securityAnswer} label="Security Answer *" type="text" value={registration.SecurityAnswer} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.securityAnswer)} />
                </InputGroup>
            </>
        )

        if (isCustomSecurityQuestion) {
            return withCutomSecurityQuestion;
        }

        return basicSecurityQuestion;
    }
    //#endregion

    return (
        <>
            {formLoading ? <div className="fixed top-2/4 m-0 left-0 right-0 z-10"><LargeSpinner /></div>
                :
                null
            }
            <Card className="p-6 flex-col">
                <h1 className="text-navy text-2xl text-left border-b border-solid border-gray-200 flex items-center">

                    <Avatar className="scale-50" /> <p className="text-lg font-semibold text-sky-800 align-text-bottom">Registration Details</p>
                </h1>

                <div className="p-10">
                    <InputGroup>
                        <Input id={FieldType.firstName} label="First Name" defaultValue={getFirstNameFromLicenceOrPassport(documentData?.FirstName)} type="text" disabled={documentData?.FirstName ? true : false} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.firstName)} />
                        <Input id={FieldType.lastName} label="Last Name" defaultValue={documentData?.LastName} type="text" disabled={documentData?.LastName ? true : false} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.lastName)} />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            id={FieldType.dateOfBirth}
                            label="Date of Birth"
                            type={new Date(documentData?.DateOfBirth ?? "").toDateString() === new Date().toDateString() ? "date" : "text"} //toDateTimeString e.g., 'Sun Jan 18 2005'
                            defaultValue={new Date(documentData?.DateOfBirth ?? "").getDate() === new Date().getDate() ?
                                new Date()
                                :
                                // format(registration?.DateOfBirth.setDate(registration?.DateOfBirth.getDate() + 1) , 'MMMM d, yyyy').toString()
                                format(adjustForTimezone(registration?.DateOfBirth), 'MMMM d, yyyy').toString()

                            }
                            disabled={new Date(documentData?.DateOfBirth ?? "").toDateString() === new Date().toDateString() ? false : true}
                            onChange={handleInputChange}
                            errorMessage={findValidationErrorById(validationMessages, FieldType.dateOfBirth)}
                        />
                        <Input id={FieldType.documentType} label="Id Type" type="text" defaultValue={documentData?.DocumentType} disabled={documentData?.DocumentType ? true : false} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.documentType)} />
                    </InputGroup>

                    <InputGroup>
                        <Input id={FieldType.documentNumber} label="Id Number" type="text" defaultValue={documentData?.DocumentNumber?.toString()} disabled={documentData?.DocumentNumber ? true : false} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.documentNumber)} />
                        <Input
                            id={FieldType.IdExpirartionDate}
                            label="Id Expiry Date"
                            type={new Date(documentData?.IdExpirartionDate ?? "").getDate() === new Date().getDate() ? "date" : "text"}
                            defaultValue={new Date(documentData?.IdExpirartionDate ?? "").getDate() === new Date().getDate() ?
                                new Date()
                                :
                                // format(registration?.IdExpirartionDate.setDate(registration?.IdExpirartionDate.getDate() + 1) , 'MMMM d, yyyy').toString()
                                format(adjustForTimezone(registration?.IdExpirartionDate), 'MMMM d, yyyy').toString()

                            }
                            disabled={new Date(documentData?.IdExpirartionDate ?? "").getDate() === new Date().getDate() ? false : true}
                            onChange={handleInputChange}
                            errorMessage={findValidationErrorById(validationMessages, FieldType.IdExpirartionDate)}
                        />
                    </InputGroup>

                    <InputGroup>
                        <Input id={FieldType.trn} label="TRN *" type="text" maxLength={9} defaultValue={documentData?.DocumentType === IdentificationDocumentType.DRIVER_LICENSE && documentData?.DocumentNumber ? documentData?.DocumentNumber?.toString() : null} disabled={documentData?.DocumentType === IdentificationDocumentType.DRIVER_LICENSE && documentData?.DocumentNumber ? true : false} onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.trn)} />
                        <Input id={FieldType.emailAddress} label="Email Address *" type="email" onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.emailAddress)} />
                    </InputGroup>

                    <InputGroup>
                        <Input id={FieldType.streetAddress} label="Street Address *" type="text"
                            onChange={handleInputChange}
                            errorMessage={findValidationErrorById(validationMessages, FieldType.streetAddress)}
                        />
                        <Input id={FieldType.city} label="City *" type="text"
                            onChange={handleInputChange}
                            errorMessage={findValidationErrorById(validationMessages, FieldType.city)}
                        />
                    </InputGroup>

                    <InputGroup>

                        <DropDownList id={FieldType.country} label="Country *" options={countries ? countries : []}
                            onChange={handleInputChange}
                            errorMessage={findValidationErrorById(validationMessages, FieldType.country)}
                        />

                        <DropDownList id={FieldType.parish} label="Parish/State *" options={regions ? regions : []}
                            onChange={handleInputChange}
                            errorMessage={findValidationErrorById(validationMessages, FieldType.parish)}
                        />
                    </InputGroup>
                    {/* security questions */}
                    {displaySecurityQuestionSection()}
                </div>
            </Card>

            <Card className="p-6 flex-col">
                <h1 className="text-navy text-2xl text-left mb-4 border-b border-solid border-gray-200 pb-1">
                    <p className="text-lg font-semibold text-sky-800 align-text-bottom">SIM Details</p>
                </h1>

                <div className="p-10">
                    <div className="flex justify-between">
                        <div className="w-full">
                            <InputGroup>
                                <label
                                    className={`ml-3 block tracking-wide text-sm font-semibold mb-1 ${activationAttempts === ActivationCodeConfigs.ACTIVATION_ATTEMPTS ? "text-gray-400" : "text-gray-700"} w-full`}
                                    htmlFor={FieldType.activationCode}
                                >
                                    Please enter <span className="font-bold">Activation Code</span>  as seen in your package
                                </label>
                                <label
                                    className={`ml-3 block tracking-wide text-sm font-semibold mb-1 ${activationAttempts === ActivationCodeConfigs.ACTIVATION_ATTEMPTS ? "text-gray-400" : "text-gray-700"} w-full`}
                                    htmlFor={FieldType.activationCode}>
                                    (case sensitive) *
                                </label>
                                <Input id={FieldType.activationCode}
                                    disabled={activationAttempts === ActivationCodeConfiguration.ACTIVATION_ATTEMPTS} defaultValue={activationCode} type="text" onChange={handleInputChange} errorMessage={findValidationErrorById(validationMessages, FieldType.activationCode)} />
                                <div className="relative mt-3 w-1/2 p-0 pb-3 ">
                                    <button type="button" disabled={disableConfirmButton} className={`${disableConfirmButton ? "bg-gray-400" : "bg-green-500"} rounded-md px-3 text-white text-md h-8 font-semibold`} onClick={onGetSimDetails}>Confirm</button>
                                </div>
                            </InputGroup>
                        </div>
                    </div>

                    <div className={`${simDetail && simDetail.length > 1 ? "h-48" : ""} overflow-y-auto ease-in-out`}>
                        {
                            simDetailLoading ?
                                <div className="transition ease-in-out duration-300">
                                    <Spinner />
                                </div>
                                :
                                <div className="transition ease-in-out duration-300">
                                    <InputGroup>
                                        {simDetail && simDetail.length > 0 ?
                                            <>
                                                {simDetail.map((sim, index) => {
                                                    return <div key={index} className="flex w-full">
                                                        <Input key={sim.iccid + sim.msisdn} id={FieldType.msisdn} label="MSISDN" type="text" disabled defaultValue={sim.msisdn} />
                                                        <Input key={sim.iccid} id={FieldType.iccid} label="ICCID" type="text" disabled defaultValue={sim.iccid} />
                                                    </div>
                                                })
                                                }
                                            </>
                                            :
                                            <>
                                                <Input id={FieldType.msisdn} label="MSISDN" type="text" disabled />
                                                <Input id={FieldType.iccid} label="ICCID" type="text" disabled />
                                            </>
                                        }
                                    </InputGroup>
                                </div>
                        }
                    </div>
                </div>
            </Card>
        </>

    )
}

export default AdditionalFormField;