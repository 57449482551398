import { ChangeEventHandler, FocusEventHandler, LegacyRef, RefObject } from "react";
import ErrorMessage from "./ErrorMessage";
import InputLabel from "./InputLabel";

interface InputProps {
    id: string;
    label?: string,
    type: string;
    placeholder?: string;
    width?: string;
    px?: string;
    disabled?: boolean;
    value?: string;
    defaultValue?: string | any;
    reference?: RefObject<HTMLInputElement>;
    onchange?: Function,
    ref?: LegacyRef<HTMLInputElement>,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    onBlur?: FocusEventHandler<HTMLInputElement>,
    errorMessage?: string | string[]
    maxLength?: number
}

function Input({ reference, value, defaultValue, id, label, type, placeholder, width = "md:w-1/2", px = "px-3", disabled = false, onchange, errorMessage, maxLength,  ref, 
onChange, onBlur, }: InputProps) {
    return (
        <div className={`w-full ${width} ${px} `}>
            <InputLabel id={id} disabled={disabled} label={label} />
            <input name={id}
            ref={ref ?? reference}
            onChange={(e) => {
                e.preventDefault();
                if (onChange) {
                    onChange(e);
                }
            }} 
            onBlur={onBlur}
            maxLength={maxLength} value={value} defaultValue ={defaultValue} disabled={disabled} className="invalid:border-red-500 mb-3 appearance-none block w-full text-gray-700 border-b border-gray-300 py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed	disabled:text-gray-400 disabled:bg-transparent" id={id} type={type} placeholder={placeholder} />
        
            <ErrorMessage errorMessage={errorMessage}  />
        </div>
    )
}

export default Input