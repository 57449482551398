import React from 'react';

function Card({ children, className, dataTestId }:{children:React.ReactNode, className?:string, dataTestId?:string}) {

  const defaultStyles = `border-solid border-1 border-gray-200 shadow-sm rounded-md overflow-hidden bg-white mb-4 w-full flex box-border border border-gray-200 ${className} `;

  return (
    <div 
      className={defaultStyles}
      data-testid={dataTestId}
    >
      { children }
    </div>
  );
}

export default Card;
