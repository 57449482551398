import Card from "../components/Cards"
import CongratulationMessages from "../config/CongratulationConfiguration.json"
import DOMPurify from 'dompurify';
import { MailIcon } from "../components/Icons";

function Congratulations() {
    return (
        <Card>
            <div className="px-20 py-12 w-full">
                <div className="relative h-14 mb-5">
                    {/* <img className=" w-16 h-16 text-purple-500" src="email-icon.png" alt="Email Icon" /> */}
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center mb-2 sm:mb-10" >
                        <MailIcon className="h-16 w-16 text-cyan mt-1 " />
                    </div>
                </div>
                <h1 className="text-green-500 pb-5 text-xl font-medium">{CongratulationMessages.WELCOME}</h1>
                <p className="leading-8 text-md" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(CongratulationMessages.CONTENT)}}></p>
            </div>
        </Card>
    )
}

export default Congratulations