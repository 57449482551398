export const InformationMessagesConfiguration = {
    AdditionalFormFields: {
        GetSimDetailNotificationLoadingMessage: {
            Title: process.env.REACT_APP_SIM_DETAIL_LOADING_TITLE?.toString() ?? "Just a moment",
            Content: process.env.REACT_APP_SIM_DETAIL_LOADING_CONTENT?.toString() ?? "Please wait while we validate the activation code",
            Duration: parseInt(process.env.REACT_APP_SIM_DETAIL_LOADING_DURATION ?? "0")
        },
        SubmitFormNotificationLoadingMessage: {
            Title: process.env.REACT_APP_FORM_LOADING_TITLE?.toString() ?? "Submitting Registration",
            Content: process.env.REACT_APP_FORM_LOADING_CONTENT?.toString() ?? "This will only take a moment"
        }
    }
}