import React from "react";
import { DisabledButton } from "./DisabledButton";

export const LoadingButton = ({
    children,
    action,
  }: {
    children?: React.ReactNode | undefined;
    action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  }) => {
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 128 128"
      className="h-4 w-4 mr-2 m-auto fill-current animate-iconLoading"
    >
      <title>Loading Icon</title>
      <path d="M64.4 16a49 49 0 00-50 48 51 51 0 0050 52.2 53 53 0 0054-52c-.7-48-45-55.7-45-55.7s45.3 3.8 49 55.6c.8 32-24.8 59.5-58 60.2-33 .8-61.4-25.7-62-60C1.3 29.8 28.8.6 64.3 0c0 0 8.5 0 8.7 8.4 0 8-8.6 7.6-8.6 7.6z">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 64 64"
          to="360 64 64"
          dur="1800ms"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );

  return <DisabledButton icon={icon}>{children}</DisabledButton>;
};
