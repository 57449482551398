import { IOption } from "../components/Inputs/DropDownList";

export interface Registration {
    id: string;
    createdDate: Date | string;
    firstName: string;
    lastName: string;
    idType: string;
    idNumber: string;
    button: string | undefined;
    idExpirartionDate: Date | string;
    email: string;
}

export interface RegistrationDetails {
    data: Registration[];
    pageIndex: number;
    totalPages: number;
    totalRecordsPerPage: number;
    totalRecordsCount: number;
}


export const configurations = {
    //TODO: retrieve the endpoint configuration from environment variable
    // BLISTER_PACK_API_ENDPOINT: process.env.REACT_APP_BASE_URL || process.env.REACT_APP_BLISTER_PACK_API_ENDPOINT, 
    Headers: {
        // Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        'accept': 'text/plain',
        'Content-Type': 'application/json',
    },
    MaxIdRetries: 4,
    signalrHubAddress: process.env.REACT_APP_SIGNALR_HUB_ADDRESS
}




export enum Gender {
    Male = "M",
    Female = "F"
}

export enum IdVerificationRegistrationStatus {
    APPROVED = 'APPROVED',
    SUCCESSFUL = 'SUCCESSFUL',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
}

export enum IdentificationDocumentTypeEnum {
    UNKNOWN = "UNKNOWN",
    ID_CARD = "ID_CARD",
    PASSPORT = "PASSPORT",
    RESIDENCE_PERMIT = "RESIDENCE_PERMIT",
    DRIVER_LICENSE = "DRIVER_LICENSE",
    INTERNAL_PASSPORT = "INTERNAL_PASSPORT",
    SOCIAL_ID = "SOCIAL_ID"
}

export const IdentificationDocumentType = {
    UNKNOWN: process.env.REACT_APP_ID_TYPE_UNKNOWN ?? "Uknown",
    ID_CARD: process.env.REACT_APP_ID_TYPE_ID_CARD ?? "IdCard",
    PASSPORT: process.env.REACT_APP_ID_TYPE_PASSPORT ?? "Passport",
    RESIDENCE_PERMIT: process.env.REACT_APP_ID_TYPE_RESIDENCE_PERMIT ?? "ResidentPermit",
    DRIVER_LICENSE: process.env.REACT_APP_ID_TYPE_DRIVER_LICENSE ?? "DriverLicence",
    INTERNAL_PASSPORT: process.env.REACT_APP_ID_TYPE_INTERNAL_PASSPORT ?? "InternalPassport",
    SOCIAL_ID: process.env.REACT_APP_ID_TYPE_INTERNAL_SOCIAL_ID ?? "SocalId"
}

//#region IdTypeMapping
const {
    REACT_APP_ID_TYPE_DRIVER_LICENSE,
    REACT_APP_ID_TYPE_DRIVER_LICENSE_ID,
    REACT_APP_ID_TYPE_PASSPORT,
    REACT_APP_ID_TYPE_PASSPORT_ID,
    REACT_APP_ID_TYPE_ID_CARD,
    REACT_APP_ID_TYPE_ID_CARD_ID,
    REACT_APP_ID_TYPE_INTERNATIONAL_ID

} = process.env;

export const IdTypeMapping = Object.freeze({
    DRIVER_LICENSE: { REACT_APP_ID_TYPE_DRIVER_LICENSE, REACT_APP_ID_TYPE_DRIVER_LICENSE_ID: Number(REACT_APP_ID_TYPE_DRIVER_LICENSE_ID) },
    PASSPORT: { REACT_APP_ID_TYPE_PASSPORT, REACT_APP_ID_TYPE_PASSPORT_ID: Number(REACT_APP_ID_TYPE_PASSPORT_ID) },
    ID_CARD: { REACT_APP_ID_TYPE_ID_CARD, REACT_APP_ID_TYPE_ID_CARD_ID: Number(REACT_APP_ID_TYPE_ID_CARD_ID) },
    INTERNATIONAL: { REACT_APP_ID_TYPE_INTERNATIONAL_ID: Number(REACT_APP_ID_TYPE_INTERNATIONAL_ID) },
    NO_MATCH: 0,
})
//#endregion

export interface DocumentData {
    FirstName: string,
    MiddleName: string,
    LastName: string,
    PersonalIdentityCode: number,
    DateOfBirth: string,
    Nationality: string,
    Gender: string,
    Address: string,
    Country: string,
    DocumentNumber: string,
    DocumentType: string,
    DateOfIssue: string,
    IdExpirartionDate: string
}

export interface UserRegistration {
    FirstName: string,
    MiddleName: string,
    LastName: string,
    PersonalIdentityCode: number,
    DateOfBirth: string,
    Nationality: string,
    Gender: string,
    Address: string,
    Country: string,
    IDNumber: string,
    IDTypeId: number,
    DateOfIssue: string,
    IdExpirartionDate: string,
    Trn: string,
    Email: string,
    City: string,
    RegionId: number,
    SecurityQuestionId: number,
    CustomSecurityQuestion: string,
    SecurityAnswer: string,
    ActivationCode: string,
    TermsAndConAccepted: boolean,
    ActivationCodeValid: boolean;
}
export interface DropDownListData {
    id: number,
    name: string
}
export interface DropDownListResponse {
    success: boolean,
    message: string,
    data: IOption[]
}

export interface VerifiedCustomerDetails {
    status: string,
    hasError: boolean,
    message: string,
    data: {
        id: string,
        applicationId: string,
        createdUtc: Date,
        setup: {
            id: string,
            versionId: string
        },
        identityVerificationId: string,
        status: number,
        statusReason: string,
        isCrossChecked: true,
        document: {
            fullName: string,
            firstName: string,
            middleName: string,
            lastName: string,
            documentNumber: string,
            dateOfIssue: Date,
            dateOfExpiration: Date,
            dateOfBirth: Date,
            personalCode: string,
            address: string,
            nationalityIso3: string,
            countryIso3: string,
            countryStateIso2: string,
            gender: string,
            type: string,
            mrzVerified: true,
            category: string
        },
        proofOfAddress: {
            firstName: string,
            lastName: string,
            street: string,
            city: string,
            parish: string
        },
        completedUtc: Date
    }
}

export const routes = {
    // HomeIncompleteRegistrations: process.env.REACT_APP_HOME_INCOMPLETE_REGISTRATIONS, 
    // SIMPackageListing: process.env.REACT_APP_SIM_PACKAGE_LISTING,
    IdTypes: process.env.REACT_APP_ID_TYPES,
    SimQuotaCheck: process.env.REACT_APP_SIM_QUOTA_CHECK,
    StartIdentification: process.env.REACT_APP_START_IDENTIFICATION_ENDPOINT,
    getSimDetails: process.env.REACT_APP_GET_SIM_DETAILS,
    submitRegistration: process.env.REACT_APP_SUBMIT_REGISTRATION,
    getRegions: process.env.REACT_APP_GET_REGIONS,
    getSecurityQuestions: process.env.REACT_APP_GET_SECURITY_QUESTIONS,
    getCustomerIdVerificationData: process.env.REACT_APP_GET_CUSTOMER_DETAILS,
    getCountries: process.env.REACT_APP_GET_COUNTRIES,
}
