import { IconButton } from "./IconButton";

export const DisabledButton = ({
  children,
  icon,
  onMouseEnter,
  action,
}: {
  children?: React.ReactNode | undefined;
  icon?: React.ReactNode | undefined;
  action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <IconButton
      className="cursor-not-allowed opacity-50"
      onMouseEnter={onMouseEnter}
      disabled={true}
      icon={icon}
      action={action}
    >
      <span className="animate-textFade opacity-50 flex justify-center align-center">
        {children}
      </span>
    </IconButton>
  );
};
