import React, { useState } from 'react'

interface Props {
  screenSize: number
}
const ScheduleTwoTable = ({ screenSize }: Props) => {

  const ScheduleTwoTableStyle: React.CSSProperties = {
    width: '100%',
    borderRadius: '0.20rem',
    color: 'black',
    border: '1px solid black', // Added border property
    textAlign: 'start',

    // all except third table data uses this
    overflowWrap: screenSize < 375 ? 'break-word' : 'normal',
    wordBreak: screenSize < 375 ? 'break-all' : 'normal',
    whiteSpace: screenSize < 375 ? 'pre-line' : 'normal',

  };

  const EntityColumnStyle: React.CSSProperties = {
    width: '35%',
    padding: '.5rem',
    fontWeight: 'bold',
    borderRight: '1px solid black', // Added borderRight property
    paddingLeft: screenSize < 640 ? '.5rem' : '3rem',
  };

  const CountryColumnStyle: React.CSSProperties = {
    width: '30%',
    padding: '.5rem',
    fontWeight: 'bold',
    borderRight: '1px solid black', // Added borderRight property
    paddingLeft: screenSize < 640 ? '.5rem' : '3rem',
  };

  const RestrictionDataColumnStyle: React.CSSProperties = {
    width: '35%',
    padding: '.5rem',

    overflowWrap: 'normal',
    wordBreak: 'normal',
    whiteSpace: 'normal',
  } as React.CSSProperties

  const ScheduleTwoTableRow = (entity: string, country: string, restrictions: string) => (
    <li style={{ borderBottom: '1px solid black' }} className="flex">
      <div style={{...EntityColumnStyle, ...{fontWeight: 'normal'}}}>{entity}</div>
      <div style={{...CountryColumnStyle, ...{fontWeight: 'normal'} }}>{country}</div>
      <div style={RestrictionDataColumnStyle}>{restrictions}</div>
    </li>
  );

  const ScheduleTwoTable = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ul style={ScheduleTwoTableStyle}>
        <li style={{ borderBottom: '1px solid black' }} className="flex">
          <div style={EntityColumnStyle}>DIGICEL ENTITIES</div>
          <div style={CountryColumnStyle}>COUNTRIES</div>
          <div style={{ ...EntityColumnStyle, ...{ borderRight: 'none' } }}>SIM PURCHASE RESTRICTION*</div>
        </li>
        {ScheduleTwoTableRow("Wireless Ventures (Anguilla) Limited", "Anguilla", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Antigua Wireless Ventures Limited", "Antigua & Barbuda", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel Aruba Holdings B.V", "Aruba", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (Barbados) Limited", "Barbados", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Telecommunications (Bermuda and West Indies) Ltd.", "Bermuda", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel Suriname N.V.", "Suriname", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Antilliano Por N.V. T/A Digicel Bonaire", "Bonaire", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (BVI) Limited", "British Virgin Islands", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (SVG) Limited", "St Vincent & the Grenadines", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (Cayman) Limited", "Cayman", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (Trinidad & Tobago) Limited", "Trinidad & Tobago", "No more than 5 SIM cards every 120 days")}
        {ScheduleTwoTableRow("Curacao Telecom N.V. T/A Digicel Curaçao", "Curacao", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (Dominica) Limited", "Dominica", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (Grenada) Limited", "Grenada", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("U-Mobile (Cellular) Inc. T/A Digicel Guyana", "Guyana", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (Jamaica) Limited", "Jamaica", "No more than 5 SIM cards every 120 days")}
        {ScheduleTwoTableRow("Caribbean Cable Communications (Montserrat) Limited", "Montserrat", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel (St Lucia) Limited", "St Lucia", "No more than 2 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel St Kitts Wireless Ventures (St. Kitts & Nevis) Limited T/A Digicel St. Kitts & Nevis", "St Kitts & Nevis", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel Turks and Caicos Ltd", "Turks & Caicos", "No more than 4 SIM cards within 365 days")}
        {ScheduleTwoTableRow("Digicel S.A. de C.V.", "El Salvador", "No more than 5 SIM cards every 120 days")}
        {ScheduleTwoTableRow("Unigestion Holding S.A.", "Haiti", "No more than 5 SIM cards every 120 days")}
      </ul>
    </div>
  );

  return (
    <>
      {ScheduleTwoTable()}
    </>
  )
}

export default ScheduleTwoTable