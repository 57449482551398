import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { WizardContextProvider } from "./context/WizardContext";
import { SignalRContextProvider } from "./context/SignalRContext";
import { FlowType } from "./services/enums";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContextProvider>
        {process.env.REACT_APP_FLOW_TYPE === FlowType.SAMETAB ?
          <WizardContextProvider>
            <App />
          </WizardContextProvider>
          :
          <SignalRContextProvider>
            <WizardContextProvider>
              <App />
            </WizardContextProvider>
          </SignalRContextProvider>
        }
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
