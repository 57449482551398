import React from "react";
import {
  STEP_INIT,
  STEP_PROCESSING,
  STEP_SUCCESS,
  STEP_FAILED,
  STEP_ACTIVE,
  STEP_INACTIVE,
} from "../../config/constants";
import Button from "./Button";
import { DisabledButton } from "./DisabledButton";
import { FailButton } from "./FailButton";
import { LoadingButton } from "./LoadingButton";
import { SuccessButton } from "./SuccessButton";

export const ProgressButton = ({
  label,
  action,
  state = STEP_INIT,
  onMouseEnter,
}: {
  label: React.ReactNode;
  action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  state: string;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  switch (state) {
    case STEP_PROCESSING:
      return <LoadingButton action={action}>{label}</LoadingButton>;
    case STEP_SUCCESS:
      return <SuccessButton action={action}>{label}</SuccessButton>;
    case STEP_FAILED:
      return <FailButton action={action}>{label}</FailButton>;
    case STEP_ACTIVE:
      return <Button action={action}>{label}</Button>;
    case STEP_INACTIVE:
      return (
        <DisabledButton onMouseEnter={onMouseEnter}>{label}</DisabledButton>
      );
    default:
      return <Button action={action}>{label}</Button>;
  }
};
