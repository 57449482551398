import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { MessageCard } from "../components/Cards";
import { MessageCardType } from "../components/Cards/MessageCard";
import Logger from "../utils/Logger";
import AlertConfiguration from "../../src/config/AlertConfiguration.json"

interface ApplicationCtx {
  showAppError: (title?: string, content?: string, cardType?:MessageCardType, duration?:number) => void;
  setHasAppError: React.Dispatch<React.SetStateAction<boolean>>;
}
const SOURCE = "App Component";

const DEFAULT_ERROR = {
  title: "Unfortunately, There was an error",
  content:
    "There was an error processing your data. This issue has been reported. Please check back later. Contact Customer Care if the error persists.",
  duration: AlertConfiguration.DEFAULT_ALERT_TIMEOUT
};
export const ApplicationContext = React.createContext<
  ApplicationCtx
>({
  showAppError: () => {console.log("initial")},
  setHasAppError: () => {},
});

export const AppContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [appError, setAppError] = useState(true);
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState<number>(0);
  const [content, setContent] = useState("");
  const [messageCardType, setMessageCardType] = useState<MessageCardType>(MessageCardType.Default)

  const showAppError = (
    title = DEFAULT_ERROR.title,
    content = DEFAULT_ERROR.content,
    cardType = MessageCardType.Default,
    duration= DEFAULT_ERROR.duration
  ) => {
    setTitle(title);
    setContent(content);
    setAppError(false);
    setDuration(duration);
    setMessageCardType(()=>{
      return cardType;
    });
  };

  const handleClose = (e: React.SyntheticEvent) => {
    Logger.info("Entered the handleClose function", SOURCE);
    setAppError(true);
    Logger.info("Entered the handleClose function", SOURCE);
  };
  return (
    <ApplicationContext.Provider
      value={{ showAppError:showAppError, setHasAppError: setAppError }}
    >
      {children}
      <div
        className={`p-2 absolute bottom-0 right-0 w-full  flex items-center   ${
          appError ? "hidden" : "flex h-full bg-gray-600 bg-opacity-25"
        }
    md:p-4 md:bg-none md:h-auto md:w-3/4 lg:w-1/3 md:bg-transparent
    `}
      >
        <MessageCard title={title} close={appError} onClose={handleClose} type={messageCardType} duration={duration}>
          {content}
        </MessageCard>
      </div>
    </ApplicationContext.Provider>
  );
};
