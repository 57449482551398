import React from "react";

function Stage({
  children,
  url,
  state = "inactive",
}: {
  children?: React.ReactNode | undefined;
  url?: string;
  state?: string;
}) {
  switch (state) {
    case "active":
      return (
        <li className="mr-6 ">
          <a className={`font-bold text-navy`} href={url}>
            {children}
          </a>
        </li>
      );
    case "inactive":
      return (
        <li className="mr-6 ">
          <a className={`font-semibold text-navy`} href={url}>
            {children}
          </a>
        </li>
      );
    default:
      return (
        <li className="mr-6 ">
          <a className={`font-semibold text-gray-400`} href={url}>
            {children}
          </a>
        </li>
      );
  }
}

export default Stage;
