import { ReactNode } from "react"

function InputGroup({children, className="flex flex-wrap mb-8"}: {children: ReactNode, className?: string}) {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default InputGroup