import { useEffect, useState } from "react";

export function usePersistentStorageValue<T>(key: string, initialValue?: T) {
    const [value, setValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            const valueFromStorage = JSON.parse(item!);
            if (
                typeof initialValue === 'object' && !Array.isArray(initialValue) && initialValue !== null) {
                return {
                    ...initialValue,
                    ...valueFromStorage,
                };
            }
            return valueFromStorage || initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });
    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
        // clear storage on unmount    
        return () => window.localStorage.removeItem(key);
    }, [key, value]);
    return [value, setValue] as const;
}