import { createContext, Dispatch, FunctionComponent, MutableRefObject, PropsWithChildren, SetStateAction, useCallback, useRef, useState } from "react";

interface WizardCtx {
    isValid: boolean;
    setIsValid: Dispatch<SetStateAction<boolean>>;
    action: () => Promise<{ redirect: string } | undefined>;
    setAction:  Dispatch<SetStateAction<() => Promise<{redirect: string; } | undefined>>>;
    checkErrors: boolean;
    setCheckErrors: Dispatch<SetStateAction<boolean>>;
}

export const WizardContext = createContext<WizardCtx>({
    isValid: false,
    setIsValid: () => false,
    action: () => new Promise<{ redirect: string } | undefined>(() => {}),
    setAction: () => () => {},
    checkErrors: false,
    setCheckErrors: () => {},
});

export const WizardContextProvider:  FunctionComponent<PropsWithChildren>  = ({
    children,
}) => {
    const [action, setAction] = useState(() => () => new Promise<{ redirect: string } | undefined>(() => {}));
    const [isValid, setIsValid] = useState(false);
    const [checkErrors, setCheckErrors] = useState(() => true); 

    return (
        <WizardContext.Provider value={{
            isValid,
            setIsValid,
            action,
            setAction,
            checkErrors,
            setCheckErrors}}>
            {children}
        </WizardContext.Provider>
    );
};
