import { IdTypeMapping } from "../services/configs";


export const getSelectedIdType = (selectedIdType: number) => {
    switch (selectedIdType) {
        case IdTypeMapping.DRIVER_LICENSE.REACT_APP_ID_TYPE_DRIVER_LICENSE_ID:
            return IdTypeMapping.DRIVER_LICENSE.REACT_APP_ID_TYPE_DRIVER_LICENSE;
        case IdTypeMapping.PASSPORT.REACT_APP_ID_TYPE_PASSPORT_ID:
            return IdTypeMapping.PASSPORT.REACT_APP_ID_TYPE_PASSPORT;
        case IdTypeMapping.ID_CARD.REACT_APP_ID_TYPE_ID_CARD_ID:
            return IdTypeMapping.ID_CARD.REACT_APP_ID_TYPE_ID_CARD;
        default:
            return "unknown";
    }
}

export const isIdTypeMismatched = (idNumber: string, selectedIdTypeId: number, documentNumber?: string, type?: string) => {

    if (selectedIdTypeId === IdTypeMapping.INTERNATIONAL.REACT_APP_ID_TYPE_INTERNATIONAL_ID) { //international id type was selected
        if (idNumber !== documentNumber) { //international id type was selected
            return true;
        }
        else {
            return false;
        }
    }
    else if (idNumber !== documentNumber || getSelectedIdType(selectedIdTypeId) !== type) {
        return true;
    }
    return false;
}