import React, { useState } from 'react'

interface Props {
    screenSize: number
}
const ScheduleOneTable = ({ screenSize }: Props) => {

    const ScheduleOneTableStyle: React.CSSProperties = {
        width: '100%',
        borderRadius: '0.20rem',
        color: 'black',
        border: '1px solid black',
        textAlign: 'start',

        overflowWrap: screenSize < 375 ? 'break-word' : 'normal',
        wordBreak: screenSize < 375 ? 'break-all' : 'normal',
        whiteSpace: screenSize < 375 ? 'pre-line' : 'normal',
    };

    const EntityColumnStyle: React.CSSProperties = {
        width: '60%',
        padding: '.5rem',
        fontWeight: 'bold',
        borderRight: '1px solid black', // Added borderRight property
        paddingLeft: screenSize < 640 ? '.5rem' : '3rem',
    };

    const CountryColumnStyle: React.CSSProperties = {
        width: '40%',
        padding: '.5rem',
        fontWeight: 'bold',
        // borderRight: '1px solid black', // Added borderRight property
        paddingLeft: screenSize < 640 ? '.5rem' : '3rem',
    };

    const ScheduleOneTableRow = (entity: string, country: string) => (
        <li style={{ borderBottom: '1px solid black' }} className="flex">
            <div style={{ ...EntityColumnStyle, ...{ fontWeight: 'normal' } }}>{entity}</div>
            <div style={{ ...CountryColumnStyle, ...{ fontWeight: 'normal' } }}>{country}</div>
        </li>
    );

    const ScheduleOneTable = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ul style={ScheduleOneTableStyle}>
                <li style={{ borderBottom: '1px solid black' }} className="flex">
                    <div style={EntityColumnStyle}>ITEM 1: DIGICEL ENTITIES</div>
                    <div style={CountryColumnStyle}>ITEM 2: COUNTRIES</div>
                </li>
                {ScheduleOneTableRow("Wireless Ventures (Anguilla) Limited", "Anguilla")}
                {ScheduleOneTableRow("Antigua Wireless Ventures Limited", "Antigua & Barbuda")}
                {ScheduleOneTableRow("Digicel Aruba Holdings B.V", "Aruba")}
                {ScheduleOneTableRow("Digicel (Barbados) Limited", "Barbados")}
                {ScheduleOneTableRow("Telecommunications (Bermuda and West Indies) Ltd.", "Bermuda")}
                {ScheduleOneTableRow("Digicel Suriname N.V.", "Suriname")}
                {ScheduleOneTableRow("Antilliano Por N.V. T/A Digicel Bonaire", "Bonaire")}
                {ScheduleOneTableRow("Digicel (BVI) Limited", "British Virgin Islands")}
                {ScheduleOneTableRow("Digicel (SVG) Limited", "St Vincent & the Grenadines")}
                {ScheduleOneTableRow("Digicel (Cayman) Limited", "Cayman")}
                {ScheduleOneTableRow("Digicel (Trinidad & Tobago) Limited", "Trinidad & Tobago")}
                {ScheduleOneTableRow("Curacao Telecom N.V. T/A Digicel Curaçao", "Curacao")}
                {ScheduleOneTableRow("Digicel (Dominica) Limited", "Dominica")}
                {ScheduleOneTableRow("Digicel (Grenada) Limited", "Grenada")}
                {ScheduleOneTableRow("U-Mobile (Cellular) Inc. T/A Digicel Guyana", "Guyana")}
                {ScheduleOneTableRow("Digicel (Jamaica) Limited", "Jamaica")}
                {ScheduleOneTableRow("Caribbean Cable Communications (Montserrat) Limited", "Montserrat")}
                {ScheduleOneTableRow("Digicel (St Lucia) Limited", "St Lucia")}
                {ScheduleOneTableRow("Digicel St Kitts Wireless Ventures (St. Kitts & Nevis) Limited T/A Digicel St. Kitts & Nevis", "St Kitts & Nevis")}
                {ScheduleOneTableRow("Digicel Turks and Caicos Ltd", "Turks & Caicos")}
                {ScheduleOneTableRow("Digicel S.A. de C.V.", "El Salvador")}
                {ScheduleOneTableRow("Unigestion Holding S.A.", "Haiti")}
            </ul>
        </div>
    );

    return (
        <>
            {ScheduleOneTable()}
        </>
    )
}

export default ScheduleOneTable