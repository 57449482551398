import { useContext, useEffect, useRef, useState } from "react";
import Card from "../../components/Cards";
import tc from "../../config/termsAndCondition.json"
import { WizardContext } from "../../context/WizardContext";
import ScheduleOneTable from "./ScheduleOneTable";
import ScheduleTwoTable from "./ScheduleTwoTable";

function TermsAndConditionsPage() {

  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const { setIsValid } = useContext(WizardContext);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    // check if environment variable exists and if it is set to true
    const enableIntersectionObserver = process.env.REACT_APP_ENABLE_TNC_SCROLL_OBSERVER !== undefined
      && process.env.REACT_APP_ENABLE_TNC_SCROLL_OBSERVER.toLowerCase() === 'true';

    if (enableIntersectionObserver) {
      const observer = new IntersectionObserver(([entry]) => {
        if (!hasScrolledToBottom && entry.isIntersecting) {
          setIsValid(entry.isIntersecting);
          setHasScrolledToBottom(true);
        }
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        observer.disconnect();
      };
    }
    else {
      setIsValid(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const additionaTermsConListStyle = {
    listStyleType: 'upper-alpha'
  }

  return (
    <>
      <Card className="p-6 flex-col">
        <h1 className="text-navy text-2xl  text-left mb-4 border-b border-solid border-gray-200 pb-1 font-medium">
          Terms and Conditions
        </h1>

        <div id="termsAndCondtion" className="mb-4 text-md text-gray-600 flex-1 max-h-96 overflow-y-auto text-justify pr-4">

          <p className="text-red-500 font-bold underline text-center">Digicel SIM-TO-GO</p>

          <p className="text-red-500 font-bold underline text-center">Terms of Use</p>
          <br />

          <p>
            This “<span className="font-bold text-black">Digicel SIM-TO-GO</span>” (the “Product”) Terms of Use (the “Terms”) apply to you and govern your usage of the Product.
          </p>
          <br />

          <p>
            “We” or “us” means the Digicel entity listed in Item 1 of Schedule 1 attached hereto, a company registered under the laws of the country identified in Item 2 of Schedule 1 attached hereto. References to “we”, “our”, “us” and “Digicel” in these Terms also include our subsidiaries and affiliates (“Affiliates”) from time to time. “I”, “You” , “Your” or “Customer” means the person using the Product.
          </p>
          <br />

          <p>
            By accepting these Terms of Use, you are agreeing to and accepting our Digicel Mobile Telephone Services Terms and Conditions, Digicel Privacy Policy, and MyDigicel App Privacy Policy which you can access via our website <a className="text-blue-600" target="_blank" href={process.env.REACT_APP_TNC_DIGICEL_LINK}>{process.env.REACT_APP_TNC_DIGICEL_LINK_LABEL}</a> and, which may be amended from time to time. If you do not agree to or accept any of these Terms, you must return and/ or stop using the Product immediately.
          </p>
          <br />

          <p>
            By activating and/ or using this Product you accept and agree to be bound by these Terms.
          </p>
          <br />
          <section id="questions" className="ml-10">
            <p className="font-bold text-black">
              1.	What is a SIM card?
            </p>
            <br />

            <p className="ml-5">
              A Subscriber Identity Module (SIM) Card, including an Embedded Subscriber Identity Module (E-SIM), is a smart card that stores identification information that pinpoints a smartphone to a specific mobile network.
            </p>
            <br />

            <p className="font-bold text-black">
              2.	What is Digicel SIM-TO-GO?
            </p>
            <br />

            <p className="ml-5">
              Digicel SIM-TO-GO is a pre-packaged kit, containing one (1) SIM card, which allows customers to register unto Digicel’s network at their convenience after purchasing the Product at any authorized Digicel sales location. With the purchase of the Product, you will be rewarded with a FREE 3-DAY Prime Bundle.
            </p>
            <br />

            <p className="font-bold text-black">
              3.	How can I register my Digicel SIM-TO-GO?
            </p>
            <br />

            <p className="ml-5">
              Before proceeding to register your SIM card, you understand and agree that you must have a valid national identification. This is a government-issued identity card with your photograph that has not expired.
            </p>
            <br />

            <p className="ml-5">
              You agree that all the steps below must be completed in order to register and activate your SIM card:
            </p>
            <br />


            <section id="registrationSteps" className="ml-10">
              <p className="flex">
                <span className="text-black font-bold mr-2 flex-shrink-0 flex-grow-0">Step 1-</span>
                <span className="">
                  Scan the QR Code or visit the website located on the inside of the SIM kit to access the activation portal from any internet-enabled device.
                </span>
              </p>
              <br />

              <p className="flex">
                <span className="text-black font-bold mr-2 flex-shrink-0 flex-grow-0">Step 2-</span>
                <span className="">
                  Follow all the instructions provided on the activation portal to complete the SIM card registration.
                </span>
              </p>
              <br />
              <p className="flex justify-start">
                <span className="text-black font-bold mr-2 flex-shrink-0 flex-grow-0">Step 3-</span>
                <span>
                  Complete the customer declaration to certify that the information provided by you during the registration process is true and complete to the best of your knowledge.

                </span>
              </p>
              <br />

              <p className="flex justify-start">
                <span className="text-black font-bold mr-2 flex-shrink-0 flex-grow-0">Step 3-</span>
                <span>
                  Carefully remove the SIM card from its holder and place it in the slot provided in your mobile phone. Turn on your phone and dial 122 to activate your account as soon as possible. If your SIM card is not activated within seven  (7) days it will be automatically deactivated and you will have to purchase a new SIM card to gain access to Digicel’s network.
                </span>
              </p>
              <br />
            </section>

            <p className="font-bold text-black">
              4.	Is there a time period by which I am required to register my Digicel SIM-TO-GO?
            </p>
            <br />

            <p className="ml-5">
              Yes. You are required to register your Digicel SIM-TO-GO within 7 days of activation.
            </p>
            <br />

            <p className="font-bold text-black">
              5.	Are there restrictions on the use of the Digicel SIM-TO-GO before it is registered?
            </p>
            <br />

            <p className="ml-5">
              You will not be able to make any chargeable activities (calls, text messages) or receive any calls or texts until you have successfully registered your SIM card. However, you will be able to contact and receive calls from Digicel’s customer care.
            </p>
            <br />

            <p className="font-bold text-black">
              6.	Is there a limit on how many Digicel SIMs-TO-GO I may purchase?
            </p>
            <br />

            <p className="ml-5">
              You acknowledge that each Customer within each country will only be able to purchase the maximum number of SIM cards, as outlined in Schedule 2 attached hereto.
            </p>
            <br />

          </section>

          <p className="font-bold text-black">
            Additional Terms and Conditions
          </p>
          <br />

          <section id="addtionalTermsAndConditionBody" className="ml-16">
            <ol style={additionaTermsConListStyle}>
              <li>
                The SIM card is your property and you must keep your SIM Card safe and in good condition.
              </li>
              <br />
              <li>
                Any theft, loss of, or damage to your SIM card is your liability and you must pay us a fee to replace any stolen, lost, or damaged SIM Cards. In the event that a SIM Card is faulty or defective, it must be returned to our Customer Support center at any Digicel store for a replacement to be issued, after an assessment.
              </li>
              <br />
              <li>
                This Product is offered for the personal use of individuals and not commercial use or usage by groups of individuals. Any usage by an individual that is determined, in Digicel’s sole discretion, to be intended for financial gain or profit by you or by groups of individuals will result in immediate termination.
              </li>
              <br />
              <li>
                Digicel reserves the right to immediately terminate your use of this Product if we have reasonable cause to believe that the Product was obtained fraudulently or through fraudulent or improper means.
              </li>
              <br />
              <li>
                You are solely responsible for registering and activating your SIM card after purchase.
              </li>
              <br />
              <li>
                You are solely responsible for all associated fees and costs incurred by you for connectivity and data usage in relation to your use of the Product.
              </li>
              <br />
              <li>
                Digicel reserves the right to modify or discontinue (permanently or temporarily) this Product being offered to its customer at its sole discretion at any time.
              </li>
              <br />
              <li>
                For customer support, or to report a problem or send us your feedback, please contact us through the customer support chat feature on the My Digicel Application              </li>
              <br />
              <li>
                Digicel reserves the right at any time, with or without notice, to vary or cancel the Terms of Use for this Product. In the event of Digicel giving notice to customers of any such changes to or cancellation of the Terms of Use for this Product, it shall suffice for Digicel to give customers notice via messages to the customer’s handset/device or to post such notification on Digicel’s website. Any such notification shall be effective immediately or as of the date referred to in such notification.              </li>
            </ol>

          </section>

          <br />
          <br />
          <p className="text-black font-bold text-center">SCHEDULE 1- LIST OF DIGICEL ENTITIES</p>

          <br />
          {/* TODO: SHOULD BE ADDED TO CONFIGURATION ON THE NEXT CHANGE REQUEST */}
          <section id="schedule1" className="">
            <ScheduleOneTable screenSize={screenSize} />
          </section>

          <br />
          <br />
          <p className="text-black font-bold text-center">SCHEDULE 2- SIM PURCHASE RESTRICTION</p>

          <br />
          <section id="schedule2" className="">
            <ScheduleTwoTable screenSize={screenSize} />
          </section>

          <br />
          <p className="text-black font-bold text-start">*The content of this table is subject to change as a result of new and/ or amended Market Regulations.  </p>

          <div ref={containerRef} />
        </div>

      </Card>
    </>
  );
}

export default TermsAndConditionsPage;
