import { ChevronDownIcon } from "@heroicons/react/outline";
import { ChangeEventHandler, LegacyRef } from "react";
import ErrorMessage from "./ErrorMessage";
import InputLabel from "./InputLabel";

export interface IOption {
  id?: number;
  name: string;
}

interface IDropDownListProps {
  id: string;
  ref?: LegacyRef<HTMLSelectElement> | undefined;
  label?: string;
  disabled?: boolean;
  options: IOption[] | string[];
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  width?: string;
  px?: string;
  value?: string | number | readonly string[] | undefined;
  errorMessage?: string | string[];
}

function DropDownList({
  id,
  ref,
  label,
  errorMessage = undefined,
  disabled = false,
  options,
  onChange,
  value,
  width = "md:w-1/2",
  px = "px-3",
}: IDropDownListProps) {
  const disabledDropDownIcon = disabled ? "text-gray-400" : "text-gray-700";

  return (
    <div className={`w-full ${width} ${px}`}>
      <InputLabel id={id} disabled={disabled} label={label} />
      <div className="relative mb-3">
        <select
          title={label}
          ref={ref}
          onChange={(e) => {
            e.preventDefault();
            if (onChange) {
              e.target?.setAttribute("name", id);
              console.log("erfsd", e)
              onChange(e);
            }
          }}
          value={value}
          id={id}
          disabled={disabled}
          className="invalid:border-red-500 block appearance-none w-full border-b border-gray-300 text-gray-700 py-3 px-2 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 disabled:cursor-not-allowed disabled:text-gray-700  disabled:bg-transparent"
        >
          {options.map((opt, index) =>
            typeof opt === "string" ? (
              <option value={index} key={index}>
                {opt}
              </option>
            ) : (
              <option value={opt.id ?? index} key={opt.id ?? index}>
                {opt.name}
              </option>
            )
          )}
        </select>
        {/* when there are error messages, the chevron shifts down */}
        <div
          className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 ${disabledDropDownIcon} ${
            errorMessage && errorMessage?.length > 0 ? "pb-8" : ""
          }`}
        >
          <ChevronDownIcon className="fill-current h-4 w-4" />
        </div>
      </div>
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}

export default DropDownList;
