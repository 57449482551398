export const ErrorMessageCongiguraton = {
    AdditionalFormFields: {
        ActivationCodeIsRequired: process.env.REACT_APP_ACTIVATION_CODE_REQUIRED?.toString() ??  "Activation Code is required",
        FirstNameIsRequired: process.env.REACT_APP_FIRST_NAME_REQUIRED?.toString() ?? "First name is required",
        LastNameIsRequired: process.env.REACT_APP_LAST_NAME_REQUIRED?.toString() ?? "Last name is required",
        DateOfBirthIsRequired: process.env.REACT_APP_DATE_OF_BIRTH_REQUIRED?.toString() ?? "Date of Birth is required",
        IdTypeIsRequired: process.env.REACT_APP_ID_TYPE_REQUIRED?.toString() ?? "ID Type is required",
        IDNumberIsRequired: process.env.REACT_APP_ID_NUMBER_REQUIRED?.toString() ?? "ID Number is required",
        IDExpiryDateIsRequired: process.env.REACT_APP_ID_EXPIRATION_DATE_REQUIRED?.toString() ?? "ID Expiration Date is required",
        TrnIsRequired: process.env.REACT_APP_TRN_REQUIRED?.toString() ?? "Trn is required",
        EmailIsRequired: process.env.REACT_APP_EMAIL_REQUIRED?.toString() ?? "Email is required",
        StreetAddressIsRequired: process.env.REACT_APP_STREET_ADDRESS_REQUIRED?.toString() ?? "Street Address is required",
        CityIsRequired: process.env.REACT_APP_CITY_REQUIRED?.toString() ?? "City is required",
        RegionIsRequired: process.env.REACT_APP_PARISH_REQUIRED?.toString() ?? "Region is required",
        CountryIsRequired: process.env.REACT_APP_COUNTRY_REQUIRED?.toString() ?? "Country is required",
        SecurityQuestionIsRequired: process.env.REACT_APP_SECURITY_QUESTION_REQUIRED?.toString() ?? "Security Question is required",
        CustomSecurityQuestionIsRequired: process.env.REACT_APP_CUSTOM_SECURITY_QUESTION_REQUIRED?.toString() ?? "Security Question is required",
        SecurityAnswerIsRequired:process.env.REACT_APP_SECURITY_ANSWER_REQUIRED?.toString() ??  "Security Answer is required",

        TrnIsLessOrMoreThanNineChars: process.env.REACT_APP_TRN_NOT_NINE?.toString() ?? "TRN must be 9 digits",
        TrnMustContainsOnlyNumbers: process.env.REACT_APP_TRN_MUST_BE_DIGITS?.toString() ?? "TRN must be 9 digits",

        EmailIsInvalid: process.env.REACT_APP_INVALID_EMAIL?.toString() ?? "Email Is Invalid",

        ActivationCodeNotFound: {
            Title : process.env.REACT_APP_ACTIVATION_CODE_NOT_FOUND_TITLE ?? "Error!",
            Content: process.env.REACT_APP_ACTIVATION_CODE_NOT_FOUND_CONTENT ?? "The activation code you entered was not found. Please check to ensure the correct information was entered and try again.",
            Duration:  Number(process.env.REACT_APP_ACTIVATION_CODE_NOT_FOUND_DURATION) 
        },

        ActivationCodeAttemptsExceeded: {
            Title : process.env.REACT_APP_ACTIVATION_CODE_EXCEEDED_TITLE ?? "Error!",
            Content: process.env.REACT_APP_ACTIVATION_CODE_EXCEEDED_CONTENT ??  "You have exhausted the number of times you are able to enter the activation code. <b>Please select the next button</b> to continue the process. <b>Please do not attempt the registration again</b> as your details have been saved and activation & registration will be completed by Digicel personnel within two (2) business days.",
            Duration:  Number(process.env.REACT_APP_ACTIVATION_CODE_EXCEEDED_DURATION) 
        },

        FormSubmissiionNotificationError: {
            Title: process.env.REACT_APP_FORM_SUBMIT_FAILURE_TITLE?.toString() ?? "Something went wrong",
            Content: process.env.REACT_APP_FORM_SUBMIT_FAILURE_CONTENT?.toString() ?? "undefined"
        },

        SimDetailNotificationError: {
            Title: process.env.REACT_APP_FORM_SIM_DETAIL_RETRIEVAL_FAILURE_TITLE?.toString() ?? "Code Not Valid",
            Content: process.env.REACT_APP_FORM_SIM_DETAIL_RETRIEVAL_FAILURE_CONTENT?.toString() ?? "",
            Duration:  Number(process.env.REACT_APP_FORM_SIM_DETAIL_RETRIEVAL_FAILURE_DURATION ) 
        },

        NoActivationCode: {
            Title: process.env.REACT_APP_NO_ACTIVATION_CODE_PROVIDED_TITLE?.toString() ?? "No activation code passed",
            Content: process.env.REACT_APP_NO_ACTIVATION_CODE_PROVIDED_CONTENT?.toString() ?? "",
            Duration:  Number(process.env.REACT_APP_NO_ACTIVATION_CODE_PROVIDED_DURATION)
        }
    },

    IdVerification : {
        CreditBureau_IdVerificationFailed : {
            Title: process.env.REACT_APP_CREDIT_BUREAU_FAILED_TITLE?.toString() ?? "Error!",
            Content: process.env.REACT_APP_CREDIT_BUREAU_FAILED_CONTENT?.toString() ?? "There was an issue with the registration process, please try again. If this persist, contact Digicel support"
        },
        CreditBureau_IdVerificationTimeout : {
            Title: process.env.REACT_APP_ID_VERIFICATION_TIMEOUT_TITLE?.toString() ?? "Error!",
            Content: process.env.REACT_APP_ID_VERIFICATION_TIMEOUT_CONTENT?.toString() ?? "Id Verification Timed Out"
        },
        CreditBureau_IdVerificationIdMismatched : {
            Title: process.env.REACT_APP_ID_VERIFICATION_ID_MISMATCHED_TITLE?.toString() ?? "Error!",
            Content: process.env.REACT_APP_ID_VERIFICATION_ID_MISMATCHED_CONTENT?.toString() ?? "The id details you initially entered do not match the id that was uploaded. Please ensure that id details are the same",
            Duration: Number(process.env.REACT_APP_ID_VERIFICATION_ID_MISMATCHED_DURATION)
        }
    }
}