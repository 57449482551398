import { DisabledButton } from "./DisabledButton";

export const FailButton = ({
  children,
  action,
}: {
  children?: React.ReactNode | undefined;
  action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      //   title="Fail Icon"
      className="h-6 w-6 mr-2 m-auto animate-successIcon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );

  return <DisabledButton icon={icon}>{children}</DisabledButton>;
};
