import { useState, useContext, useEffect } from "react";
import "./styles/App.css";
import Layout from "./components/Layout";
import TermsAndConditions from "./pages/TermsAndCondition/TermsAndConditions";
import Wizard, { WizardStep } from "./components/Wizard/Wizard";
import SimRegistrationActivation from "./pages/SimRegistrationActivation/SimRegistrationActivation";
import { WizardContext, } from "./context/WizardContext";
import WizardMessages from "./config/WizardConfiguration.json"
import Congratulations from "./pages/Congratulations";
import { useNavigate } from "react-router-dom";
import { FlowType } from "./services/enums";
import SamTabSimRegistrationActivation from "./pages/SimRegistrationActivation/SameTabSimRegistrationActivation";

function App() {
  const wizardContext = useContext(WizardContext);
  const navigate = useNavigate();
  //this is used to check if the user can move forward in the wizard
  const [preventNextStep, setPreventNextStep] = useState(() => {

    const found = localStorage.getItem('preventNextStep');
    const preventStep = found ? JSON.parse(found) : true;

    return preventStep;
  });

  const [termsAndConditionAccepted, setTermsAndConditionAccepted] = useState(() => {

    const found = localStorage.getItem('termsAndCondition');
    const termsAndCon = found ? JSON.parse(found) : false;

    return termsAndCon || false;
  });

  const [isClickSubmit, setIsClickSubmit] = useState(false);

  //set preventNextStep and termsAndConditionAccepted state in local storage
  useEffect(() => {
    localStorage.setItem('termsAndCondition', JSON.stringify(termsAndConditionAccepted));
    localStorage.setItem('preventNextStep', JSON.stringify(preventNextStep));

  }, [termsAndConditionAccepted, preventNextStep]);

  //navigate back to root if terms and condition not accepted
  useEffect(() => {
    if(!termsAndConditionAccepted)
    {
      navigate('/');
    }

    document.title = process.env.REACT_APP_APP_TITLE ?? "Digicel SIM-TO-GO";

  }, []);
  
  
  const wizardSteps: WizardStep[] = [
    {
      name: WizardMessages.FIRST_STEP_NAME,
      animation: "animate-step1 w-1/4",
      percent: "33.33%",
      url: WizardMessages.FIRST_STEP_LINK,
      label: WizardMessages.FIRST_STEP_LABEL,
      component: <TermsAndConditions />,
      action: () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            setPreventNextStep(false)
            setTermsAndConditionAccepted(true);
            resolve({ redirect: WizardMessages.SECOND_STEP_LINK });
          }, 1000);
        });
      },
      isValid: wizardContext.isValid,
      checkErrors: (show: boolean) => {
        return true;
      },
      isStageOptionClickable : false
    },
    {
      name: WizardMessages.SECOND_STEP_NAME,
      animation: "animate-step2 w-4/5",
      percent: "66.66%",
      url: preventNextStep ? "" : WizardMessages.SECOND_STEP_LINK,
      label: WizardMessages.SECOND_STEP_LABEL,
      component: process.env.REACT_APP_FLOW_TYPE === FlowType.SAMETAB ?  <SamTabSimRegistrationActivation isTermsAndConditionAccepted={termsAndConditionAccepted} /> : <SimRegistrationActivation isTermsAndConditionAccepted={termsAndConditionAccepted} />,
      action: wizardContext.action,
      isValid: wizardContext.isValid,
      isStageOptionClickable : false
    },
    {
      name: WizardMessages.THIRD_STEP_NAME,
      animation: "animate-step3 w-full",
      percent: "100%",
      url: preventNextStep ? "" : WizardMessages.THIRD_STEP_LINK,
      label: WizardMessages.THIRD_STEP_LABEL,
      component: <Congratulations />,
      action: () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ redirect: WizardMessages.FIRST_STEP_LINK });
          }, 2000);
        });
      },
      isValid: true,
      checkErrors: (show: boolean) => {
        return false;
      },
      isStageOptionClickable : false
    }
  ];

  return (
    <>
      <Layout>
        <Wizard
          steps={wizardSteps}
          hasData={true}
          hasError={isClickSubmit}
          finish={`/`}
        />
      </Layout>
    </>
  );
}
export default App;
function showAppError(arg0: string, arg1: string, arg2: boolean) {
  throw new Error("Function not implemented.");
}

