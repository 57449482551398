import React, { useState } from "react";
import { WizardStep } from "../Wizard/Wizard";

function MobileProgressBar({
  stage,
  stages,
  className,
}: {
  stage: WizardStep;
  stages: WizardStep[];
  className?: string | undefined;
}) {
  const [farthest, setFarthest] = useState(0);
  const checkFarthest = () => {
    stages.forEach((element, index) => {
      if (element === stage && index > farthest) {
        setFarthest(index);
        return;
      }
    });
  };
  checkFarthest();

  return (
    <div
      className={`w-full pb-6 max-w-full box-border ${className}`}
      data-testid="mobile-progress-bar"
    >
      <div className="flex justify-between text-center mb-2 text-lg font-semibold text-navy">
        <span>{stage.name}</span> <span>{stage.percent}</span>
      </div>
      <div className="bg-gray-300 h-2 w-full rounded">
        <div
          className={
            "bg-gradient-to-r from-cyan to-fushia h-2 rounded " +
            stages[farthest].animation
          }
        ></div>
      </div>
    </div>
  );
}

export default MobileProgressBar;
