import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../context/AppContext";
import { MessageCardType } from "../Cards/MessageCard";

// this section should be coming from the configs
const maxAttempt = 4;

export const Errors = [{
  title: "Warning",
  content:
    `The uploaded identification was not successfully verified. You may attempt verification up to ${maxAttempt} times`,
},
{
  title: "Warning",
  content:
    `The id details you initially entered do not match the uploaded identifications. Please try again.`,
},
{
  title: "Warning",
  content:
    `The uploaded identification was not successfully verified. Please contact customer care`,
}];
//

interface CardMessage{
    title: string,
    content: string
}

function NotificationLayer({ children, showErr, setShowErr, cardType, message }: { children?: React.ReactNode | undefined, showErr:boolean, setShowErr:React.Dispatch<React.SetStateAction<boolean>>, cardType?:MessageCardType, message:CardMessage}) {
    const context = useContext(ApplicationContext);
    const { showAppError, setHasAppError } = context;
    console.log("componenet triggered: ", showErr);

  
    useEffect(() => {
      console.log("use effect triggered: ", showErr);
      if (showErr) {
        console.log(cardType);
        showAppError(message.title, message.content, cardType);
        setShowErr(!showErr);
      }
      
    }, [showErr]);
  
    return (
      <>{children}</>
    );
  }
  export default NotificationLayer