import Button from "./Button";

export const IconButton = ({
  children,
  icon,
  disabled,
  action,
  className = "",
  onMouseEnter,
}: {
  children?: React.ReactNode | undefined;
  icon?: React.ReactNode | undefined;
  disabled?: boolean | undefined;
  className?: string | undefined;
  action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <span className="flex text-cyan mb-4 md:mb-0">
      {icon}
      <Button
        className={className}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
        action={action}
      >
        {children}
      </Button>
    </span>
  );
};
